import classes from './style.module.scss';
import useLoadingText from 'common/hooks/useLoadingText';
import useSelectedBasicTruckEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicTruckEarliest';
import useVehicleChangeHandler from './utils/useVehicleChangeHandler';
import useVehicleOptionsHandler from 'features/candidate/utils//vehicleOptionsHandler/useVehicleOptionsHandler';
import VehicleConfigurationFields from './VehicleConfigurationFields';
import { Button, Dropdown, Loading, TextField, useEnsureArray } from '@optimization/ssi-common';
import { chassisAdaptationTranslate, chassisHeightTranslate, toListVehicle } from '@optimization/sa-common';
import { useCallback, useMemo } from 'react';
import { useGetFactBasicDataQuery } from 'app/services/solution';
import { VehicleEnhanced } from '@optimization/sa-common';

interface Props {
  vehicle: VehicleEnhanced;
  solutionId: string;
}

const BottomDetailNonExecuted = ({ vehicle, solutionId }: Props) => {
  const factBasicDataQuery = useGetFactBasicDataQuery();
  const basicTrucks = useEnsureArray(factBasicDataQuery.data?.FactBasicTrucks);
  const optionVehicles = useMemo(() => [toListVehicle(vehicle)], [vehicle]);
  const vehicleOptionsHandler = useVehicleOptionsHandler({ vehicles: optionVehicles });

  const {
    truckChassisAdaptationOptions,
    truckChassisAdaptationDisabled,
    truckWheelConfigurationOptions,
    truckWheelConfigurationDisabled,
    truckAxleDistanceOptions,
    truckAxleDistanceDisabled,
    truckChassisHeightOptions,
    truckChassisHeightDisabled,
    truckCabTypeOptions,
    truckCabTypeDisabled,
    selections,
    handleSelection,
  } = vehicleOptionsHandler;

  const selectedBasicTruck = useSelectedBasicTruckEarliest({ basicTrucks, selections });

  const vehicleChangeHandler = useVehicleChangeHandler({
    selectedBasicTruck,
    selections,
    solutionId,
    vehicle,
  });

  const allInputsFilled = useMemo(() => Boolean(selections.truckCabType), [selections.truckCabType]);

  const disabled = vehicleChangeHandler.isLoading;

  const loadingText = useLoadingText({
    isSavingCandidate: vehicleChangeHandler.isLoading,
  });

  const cabTypeValue = useMemo(() => {
    if (vehicle.ScaniaChassis?.CabType && vehicle.ScaniaChassis?.CabLength) {
      return vehicle.ScaniaChassis.CabType.charAt(1) + vehicle.ScaniaChassis.CabLength.substring(1);
    }
    return '';
  }, [vehicle.ScaniaChassis?.CabType, vehicle.ScaniaChassis?.CabLength]);

  const handleChassisAdaptationChange = useCallback(
    (value: string) => handleSelection('truckChassisAdaptation', value),
    [handleSelection],
  );

  const handleWheelConfigurationChange = useCallback(
    (value: string) => handleSelection('truckWheelConfiguration', value),
    [handleSelection],
  );

  const handleAxleDistanceChange = useCallback(
    (value: string) => handleSelection('truckAxleDistance', value),
    [handleSelection],
  );

  const handleChassisHeightChange = useCallback(
    (value: string) => handleSelection('truckChassisHeight', value),
    [handleSelection],
  );

  const handleCabTypeChange = useCallback((value: string) => handleSelection('truckCabType', value), [handleSelection]);

  if (vehicle.ScaniaChassis?.ChassisType === 'Bus') {
    // Non executed has no bus support yet
    return null;
  }

  return (
    <>
      <div>
        <Loading
          isLoading={vehicleChangeHandler.isLoading}
          isError={vehicleChangeHandler.isError}
          loadingText={loadingText}
        />
        <h4 className="tds-detail-04">Vehicle Configuration</h4>
        <div className={classes.fields}>
          <VehicleConfigurationFields vehicleOptionsHandler={vehicleOptionsHandler} disabled={disabled} />
        </div>
      </div>
      <div>
        <h4 className="tds-detail-04">Old values</h4>
        <div className={classes.fields}>
          <TextField
            label="Chassis Adaptation"
            labelPosition="outside"
            disabled
            value={chassisAdaptationTranslate[vehicle.ScaniaChassis?.ChassisAdaptation || '']}
          />
          <TextField
            label="Wheel configuration"
            labelPosition="outside"
            disabled
            value={vehicle.ScaniaChassis?.WheelConfiguration}
          />
          <TextField
            label="Axle distance"
            labelPosition="outside"
            disabled
            value={vehicle.ScaniaChassis?.AxleDistanceMm?.toString() || ''}
          />
          <TextField
            label="Chassis height"
            labelPosition="outside"
            disabled
            value={chassisHeightTranslate[vehicle.ScaniaChassis?.ChassisHeight || '']}
          />
          <TextField label="Cab type" labelPosition="outside" disabled value={cabTypeValue} />
        </div>
      </div>
      <div>
        <h4 className="tds-detail-04">Configure new values</h4>
        <div className={classes.fields}>
          <Dropdown
            value={selections.truckChassisAdaptation}
            label="Chassis Adaptation"
            labelPosition="outside"
            placeholder="Chassis Adaptation"
            dataTestid="dropdown-non-executed-chassis-adaptation"
            options={truckChassisAdaptationOptions}
            disabled={truckChassisAdaptationDisabled || disabled}
            onChange={handleChassisAdaptationChange}
          />
          <Dropdown
            value={selections.truckWheelConfiguration}
            label="Wheel configuration"
            labelPosition="outside"
            placeholder="Wheel configuration"
            dataTestid="dropdown-non-executed-wheel-configuration"
            options={truckWheelConfigurationOptions}
            disabled={truckWheelConfigurationDisabled || disabled}
            onChange={handleWheelConfigurationChange}
          />
          <Dropdown
            value={selections.truckAxleDistance}
            label="Axle distance"
            labelPosition="outside"
            placeholder="Axle distance"
            dataTestid="dropdown-non-executed-axle-distance"
            options={truckAxleDistanceOptions}
            disabled={truckAxleDistanceDisabled || disabled}
            onChange={handleAxleDistanceChange}
          />
          <Dropdown
            value={selections.truckChassisHeight}
            label="Chassis height"
            labelPosition="outside"
            placeholder="Chassis height"
            dataTestid="dropdown-non-executed-chassis-height"
            options={truckChassisHeightOptions}
            disabled={truckChassisHeightDisabled || disabled}
            onChange={handleChassisHeightChange}
          />
          <Dropdown
            value={selections.truckCabType}
            label="Cab type"
            labelPosition="outside"
            placeholder="Cab type"
            dataTestid="dropdown-non-executed-cab-type"
            options={truckCabTypeOptions}
            disabled={truckCabTypeDisabled || disabled}
            onChange={handleCabTypeChange}
          />
          <Button
            disabled={!allInputsFilled || disabled}
            className="tds-u-ml-auto"
            variant="primary"
            text="Confirm changes"
            dataTestid="button-non-executed-confirm"
            onClick={vehicleChangeHandler.handleVehicleChange}
          />
        </div>
      </div>
    </>
  );
};

export default BottomDetailNonExecuted;
