const chartData = [
  {
    label: 'BEV',
    secondaryAxisId: 'bev',
    data: [
      {
        primary: 0,
        secondary: 0,
      },
      {
        primary: 12,
        secondary: 18310,
      },
    ],
  },
  {
    label: 'Diesel',
    secondaryAxisId: 'diesel',
    data: [
      {
        primary: 0,
        secondary: 0,
      },
      {
        primary: 12,
        secondary: 21040,
      },
    ],
  },
];

export default chartData;
