import { RadioButtonOption } from '@optimization/ssi-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

const useChassisTypeOptions = ({ selections }: { selections: SelectionExecutions }): RadioButtonOption[] =>
  useMemo(
    () => [
      { value: 'Truck', name: 'Truck', checked: selections.chassisType === 'Truck' },
      { value: 'Bus', name: 'Bus', checked: selections.chassisType === 'Bus' },
    ],
    [selections],
  );

export default useChassisTypeOptions;
