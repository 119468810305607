import classes from './style.module.scss';
import classNames from 'classnames';
import useTooltipKey from 'features/candidate/Candidates/Table/utils/useTooltipKey';
import { CANDIDATES_PER_PAGE } from 'features/candidate/Candidates';
import { getDailyRangeColumnV2, ListVehicleEnhanced, ListVehicleRowItem, NO_VALUE_SIGN } from '@optimization/sa-common';
import { IColumn, ResponsiveTable } from '@optimization/ssi-common';
import { Link } from 'react-router-dom';
import { PERFORMANCE_STEP_TOOLTIP } from 'app/config';
import { TdsIcon } from '@scania/tegel-react';
import { useMemo } from 'react';
import { useSplicedItems } from '@optimization/ssi-common';

const INITIAL_MAX_ROWS = 5;

interface Props {
  solutionId: string;
  notFeasibleVehicles: ListVehicleEnhanced[];
  totalVehicles: number;
}

const CandidatesTable = ({ solutionId, notFeasibleVehicles, totalVehicles }: Props) => {
  const tooltipKey = useTooltipKey({ vehicles: notFeasibleVehicles });

  const columns: IColumn<ListVehicleRowItem>[] = useMemo(
    () => [
      {
        columnKey: 'name',
        columnTitle: 'Name',
        render: (row: ListVehicleRowItem) => row.vehicle.transformed.chassisIdPresentation,
      },
      {
        columnKey: 'launchPeriod',
        columnTitle: 'Period',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.CompositeId ? row.vehicle.transformed.launchPeriodPresentation : NO_VALUE_SIGN,
      },
      {
        columnKey: 'performanceStep',
        columnTitle: 'Performance step',
        tooltip: PERFORMANCE_STEP_TOOLTIP,
        render: (row: ListVehicleRowItem) => row.vehicle.transformed.performanceStepPresentation,
      },
      {
        columnKey: 'designation',
        columnTitle: 'Designation',
        render: (row: ListVehicleRowItem) =>
          row.vehicle.CompositeId && row.vehicle.Designation ? row.vehicle.Designation : NO_VALUE_SIGN,
      },
      { ...getDailyRangeColumnV2({ tooltipKey }) },
      {
        columnKey: 'link',
        columnTitle: '',
        disablePadding: true,
        render: (row: ListVehicleRowItem) => (
          <Link
            className={classes['candidate-link']}
            state={{ vehicleId: row.vehicle.Id }}
            to={`/solution/${solutionId}/candidate${
              totalVehicles > CANDIDATES_PER_PAGE
                ? `?chassisNames=${row.vehicle.transformed.chassisIdPresentation}`
                : ''
            }`}
          >
            <TdsIcon className="tds-text-white" name="arrow_right" />
          </Link>
        ),
      },
    ],
    [solutionId, tooltipKey, totalVehicles],
  );

  const splicedVehicles = useSplicedItems({
    count: INITIAL_MAX_ROWS,
    items: notFeasibleVehicles,
  });

  const rows: ListVehicleRowItem[] = useMemo(
    () =>
      splicedVehicles?.map((vehicle) => ({
        vehicle,
      })) || [],
    [splicedVehicles],
  );

  return (
    <div>
      <div className="flex">
        <div className="tds-detail-04">Vehicle candidates</div>
        <Link
          to={`/solution/${solutionId}/candidate`}
          className={classNames('cursor-pointer', 'tds-text-white', 'tds-u-ml-auto', 'underline')}
        >
          Go to page
        </Link>
      </div>
      <ResponsiveTable<ListVehicleRowItem> className="mt-spacing-8" columns={columns} rows={rows} />
    </div>
  );
};

export default CandidatesTable;
