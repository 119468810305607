import Actions from './Actions';
import HelmetTitle from 'common/components/HelmetTitle';
import PageNotFound from 'common/components/PageNotFound';
import SolutionHomePageHeader from './utils/SolutionHomePageHeader';
import useLoadingText from 'common/hooks/useLoadingText';
import { BackLink } from '@optimization/sa-common';
import { invariant, Loading } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { useGetDepotsQuery, useGetSolutionQuery, useGetVehiclesQuery } from 'app/services/solution';
import { useParams } from 'react-router-dom';

const SolutionHome = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const solutionQuery = useGetSolutionQuery(solutionId);
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);

  const isLoading = solutionQuery.isFetching || vehiclesQuery.isFetching || depotsQuery.isFetching;
  const isError = solutionQuery.isError || vehiclesQuery.isError || depotsQuery.isError;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates: vehiclesQuery.isFetching,
    isLoadingDepots: depotsQuery.isFetching,
  });

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <div className="tds-container">
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <HelmetTitle solutionId={solutionId} items={['Home']} />
      {solutionQuery.isSuccess && (
        <>
          <BackLink url="/" className="mb-spacing-64">
            All solutions
          </BackLink>
          <SolutionHomePageHeader solutionId={solutionId} solutionName={solutionQuery.data.Name}>
            {solutionQuery.data.transformed.solutionNamePresentation}
          </SolutionHomePageHeader>
        </>
      )}
      <Actions solutionId={solutionId} />
    </div>
  );
};

export default SolutionHome;
