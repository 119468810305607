import classes from './style.module.scss';
import classNames from 'classnames';
import DefineManually from './DefineManually';
import Menu, { MenuItemTitle } from './utils/Menu';
import ScaniaChassis from './ScaniaChassis';
import { ButtonAlt } from '@optimization/ssi-common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const DRAWER_WIDTH_DESKTOP = 500;

interface Props {
  addVehicleDrawerIsActive: boolean;
  deactivateAddVehicleDrawer: () => void;
}

const AddVehicleDrawer = ({ addVehicleDrawerIsActive, deactivateAddVehicleDrawer }: Props) => {
  const { solutionId } = useParams();

  const [activeMenuItemTitle, setActiveMenuItemTitle] = useState<MenuItemTitle>();

  const drawerWidth = useMemo(
    () => (window.outerWidth < DRAWER_WIDTH_DESKTOP ? window.outerWidth : DRAWER_WIDTH_DESKTOP),
    [],
  );

  const onGoBack = useCallback(() => {
    setActiveMenuItemTitle(undefined);
  }, [setActiveMenuItemTitle]);

  useEffect(() => {
    if (!addVehicleDrawerIsActive) {
      setActiveMenuItemTitle(undefined);
    }
  }, [addVehicleDrawerIsActive]);

  useEffect(() => {
    document.body.style.overflow = addVehicleDrawerIsActive ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [addVehicleDrawerIsActive]);

  return solutionId ? (
    <>
      {addVehicleDrawerIsActive && <div className={classes.overlay} />}
      <div
        style={{ width: `${drawerWidth}px` }}
        className={classNames(classes.drawer, { [classes['is-active']]: addVehicleDrawerIsActive })}
      >
        <div className={classes.header}>
          {activeMenuItemTitle && (
            <ButtonAlt className={classes['back-link']} tdsIcon="chevron_left" tdsIconSize={16} onClick={onGoBack} />
          )}
          <div className="tds-headline-07">{activeMenuItemTitle || 'Add vehicle candidate'}</div>
          <ButtonAlt className={classes.close} tdsIcon="cross" tdsIconSize={16} onClick={deactivateAddVehicleDrawer} />
        </div>
        <div className={classes.body}>
          {!activeMenuItemTitle && (
            <Menu activeMenuItemTitle={activeMenuItemTitle} setActiveMenuItemTitle={setActiveMenuItemTitle} />
          )}
          {activeMenuItemTitle === 'Scania chassis' && (
            <ScaniaChassis
              solutionId={solutionId}
              drawerWidth={drawerWidth}
              deactivateAddVehicleDrawer={deactivateAddVehicleDrawer}
            />
          )}
          {activeMenuItemTitle === 'Define manually' && (
            <DefineManually
              solutionId={solutionId}
              drawerWidth={drawerWidth}
              deactivateAddVehicleDrawer={deactivateAddVehicleDrawer}
            />
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default AddVehicleDrawer;
