import { FactBasicTruck, VehicleEnhanced } from '@optimization/sa-common';
import { getEngineGearbox, getVehicleSoc } from 'common/utils/helper';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { useCallback, useMemo } from 'react';
import { useToast } from '@optimization/ssi-common';
import { useUpdateVehicleMutation } from 'app/services/solution';

interface UseHandleVehicleChangeParams {
  solutionId: string;
  vehicle: VehicleEnhanced;
  selectedBasicTruck: FactBasicTruck | null;
  selections: SelectionExecutions;
}

const useVehicleChangeHandler = ({
  solutionId,
  vehicle,
  selectedBasicTruck,
  selections,
}: UseHandleVehicleChangeParams) => {
  const showToast = useToast();
  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const handleVehicleChange = useCallback(async () => {
    if (!selectedBasicTruck) {
      return;
    }

    const response = await updateVehicle({
      solutionId,
      vehicle: {
        ...vehicle,
        ExecutedVehicleProduct: {
          ...vehicle.ExecutedVehicleProduct,
          CompositeId: selectedBasicTruck.CompositeId,
          Status: selectedBasicTruck.Status,
          LaunchPeriod: selectedBasicTruck.Period,
          Discontinued: selectedBasicTruck.Discontinued || '',
          AxleDistanceMm: Number(selections.truckAxleDistance),
          WheelConfiguration: selections.truckWheelConfiguration,
          ChassisHeight: selections.truckChassisHeight,
          ChassisAdaptation: selections.truckChassisAdaptation,
          EngineGearbox: getEngineGearbox(selectedBasicTruck, selections.truckElectricMotor),
          ChargingSpecification: selectedBasicTruck.ChargingSpecifications[0],
          Soc: getVehicleSoc(selectedBasicTruck, selections.truckSocWindow),
          Cab: selections.truckCabType,
          InstalledEnergyKWh: selectedBasicTruck.InstalledEnergyKWh,
        },
      },
    });

    if ('data' in response) {
      showToast({ header: 'Vehicle product changed successfully' });
    } else if ('error' in response) {
      showToast({ header: `Couldn't change vehicle product`, variant: 'error' });
    }
  }, [selectedBasicTruck, selections, solutionId, vehicle, updateVehicle, showToast]);

  return useMemo(
    () => ({ isLoading: updateVehicleState.isLoading, isError: updateVehicleState.isError, handleVehicleChange }),
    [updateVehicleState, handleVehicleChange],
  );
};

export default useVehicleChangeHandler;
