import ActionLayout from 'common/components/ActionLayout';
import Candidates from 'features/candidate/Candidates';
import CandidatesFilterSelector from 'features/candidate/Candidates/Filter/Selector';
import CandidatesFilterToggle from 'features/candidate/Candidates/Filter/Toggle';
import classes from './style.module.scss';
import classNames from 'classnames';
import ErrorBoundary from 'common/components/ErrorBoundary';
import InfraPerformanceStep from 'features/infrastructure/utils/InfraPerformanceStep';
import InfraPerformanceStepInfo from 'features/infrastructure/utils/InfraPerformanceStepInfo';
import PageNotFound from 'common/components/PageNotFound';
import SetupAndUpdateForm from './SetupAndUpdateForm';
import TotalCount from 'common/components/TotalCount';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useCreateAndUpdateDepot from './utils/useCreateAndUpdateDepot';
import useDepotBasePowerDemands from './utils/useDepotBasePowerDemands';
import useHandleSelectAll from 'common/hooks/useHandleSelectAll';
import useInfraPerformanceStepWizardHandler from './utils/useInfraPerformanceStepWizardHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useProgressPercent, { DEPOT_INFRA_PROGRESS_PERCENT } from './utils/useProgressPercent';
import useSelectCandidateRow from 'features/candidate/utils/useSelectCandidateRow';
import useStepInfo from './utils/useStepInfo';
import useVehiclesMappedToOtherDepot from 'features/infrastructure/DepotDetail/utils/useVehiclesMappedToOtherDepot';
import { isError404 } from 'common/utils/helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useElementController } from 'app/context/ElementControllerContext';
import { useEnhancedListVehicles } from '@optimization/sa-common';
import { useExecutedVehicles, useVehicleSettingsHandler } from '@optimization/sa-common';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import {
  Button,
  KeyedNumber,
  ProgressBar,
  Checkbox,
  InlineTabs,
  Loading,
  Tooltip,
  usePlural,
  TInlineTabs,
  Modal,
} from '@optimization/ssi-common';
import {
  useGetDepotQuery,
  useGetSolutionQuery,
  useGetDepotsQuery,
  useGetVehiclesQuery,
  useGetFactBasicDataQuery,
} from 'app/services/solution';

export type Mode = 'update-depot' | 'setup-depot' | 'map-vehicles' | 'depot-infrastructure';

type ModalType = 'cancel-depot';

interface Props {
  solutionId: string;
  depotId?: string;
  onCancelDepotForm: () => void;
}

const DepotForm = ({ solutionId, depotId, onCancelDepotForm }: Props) => {
  const elementController = useElementController();

  const [mode, setMode] = useState<Mode>(solutionId && depotId ? 'update-depot' : 'setup-depot');

  const actionLayoutRef = useRef<HTMLDivElement | null>(null);
  const createDepotRef = useRef<HTMLDivElement | null>(null);

  const [depotYear, setDepotYear] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [modal, setModal] = useState<ModalType | null>(null);

  const [depotName, setDepotName] = useState('');
  const [depotMaxPowerCapacity, setDepotMaxPowerCapacity] = useState('');
  const [depotFutureMaxPowerCapacity, setDepotFutureMaxPowerCapacity] = useState('');
  const [depotBasePowerDemand, setDepotBasePowerDemand] = useState<KeyedNumber>({
    '00-06': 0,
    '06-12': 0,
    '12-18': 0,
    '18-24': 0,
  });

  const depotBasePowerDemands = useDepotBasePowerDemands({
    depotBasePowerDemand,
  });

  const depotQuery = useGetDepotQuery({ solutionId, depotId: depotId || '' }, { skip: Boolean(!depotId) });

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const solutionQuery = useGetSolutionQuery(solutionId);
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);

  const executedVehicles = useExecutedVehicles({
    vehicles: vehiclesQuery.data,
  });

  const enhancedVehicles = useEnhancedListVehicles({
    vehicles: executedVehicles,
    depotYear,
    factBasicDataQueryData: factBasicDataQuery.data,
  });

  const vehiclesMappedToOtherDepot = useVehiclesMappedToOtherDepot({
    depots: depotsQuery.data,
    depotId: depotId || '',
    vehicles: enhancedVehicles,
  });

  const vehiclesAvailableForMapping = useMemo(
    () => enhancedVehicles.filter((vehicle) => !vehiclesMappedToOtherDepot?.[vehicle.Id]) || [],
    [enhancedVehicles, vehiclesMappedToOtherDepot],
  );

  const vehicleSettingsHandler = useVehicleSettingsHandler();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    selectedMode: 'map-vehicles',
    selectedCandidates,
    vehiclesMappedToOtherDepot,
    vehicleSettingsById: vehicleSettingsHandler.vehicleSettingsById,
  });

  const infraPerformanceStepHandler = useInfraPerformanceStepWizardHandler({
    depotYear,
    chargingSolutions: factBasicDataQuery.data?.ChargingSolutions,
    depotBasePowerDemands,
    depotFutureMaxPowerCapacity,
    depotMaxPowerCapacity,
    depotName,
    mode,
    selectedCandidates,
    solutionId,
  });

  const createAndUpdateDepot = useCreateAndUpdateDepot({
    depotBasePowerDemands,
    mode,
    solutionId,
    depot: depotQuery.data,
    depotName,
    depotMaxPowerCapacity,
    depotFutureMaxPowerCapacity,
    selectedCandidates,
    chargers: infraPerformanceStepHandler.infraPerformanceStep?.chargers || [],
    setMode,
  });

  const { setProgressPercent, progressPercent } = useProgressPercent({
    mode,
    selectedCandidates,
    infraPerformanceStepHandler,
  });

  const activateDepotInfrastructureMode = useCallback(() => {
    setMode('depot-infrastructure');
    setProgressPercent(DEPOT_INFRA_PROGRESS_PERCENT);
  }, [setProgressPercent]);

  const onNextYear = useCallback(() => {
    setTabIndex((prev) => prev + 1);
  }, []);

  const handleSelectRow = useSelectCandidateRow({
    setSelectedCandidates,
    vehiclesMappedToOtherDepot,
  });

  const handleSelectAllShown = useHandleSelectAll({
    items: candidatesHandler.splicedCandidates,
    setSelectedItems: setSelectedCandidates,
  });

  const handleSelectAllExisting = useHandleSelectAll({
    items: candidatesHandler.filteredCandidates,
    setSelectedItems: setSelectedCandidates,
  });

  const candidatesPluralUppercase = usePlural({
    count: selectedCandidates.length,
    uppercase: true,
  });

  const stepInfo = useStepInfo({ mode });

  useEffect(() => {
    if (infraPerformanceStepHandler.years?.[tabIndex]) {
      setDepotYear(infraPerformanceStepHandler.years[tabIndex]);
    }
  }, [tabIndex, infraPerformanceStepHandler.years]);

  const tabs: TInlineTabs<string> = useMemo(
    () =>
      infraPerformanceStepHandler.years?.map((year, index) => ({
        name: year,
        color: infraPerformanceStepHandler.infraPerformanceSteps?.[index].stepIsComplete ? 'green' : 'grey',
      })) || [],
    [infraPerformanceStepHandler],
  );

  useEffect(() => {
    if (elementController) {
      elementController.setEnableSidebar(['map-vehicles', 'depot-infrastructure'].includes(mode) ? false : true);
    }

    return () => {
      if (elementController) {
        elementController.setEnableSidebar(true);
      }
    };
  }, [mode, elementController]);

  useEffect(() => {
    if (mode === 'map-vehicles' && vehiclesQuery.isSuccess && !vehiclesAvailableForMapping.length) {
      setMode('depot-infrastructure');
    }
  }, [mode, vehiclesAvailableForMapping, vehiclesQuery.isSuccess]);

  const showSaveAndExitModal = useCallback(() => {
    setModal('cancel-depot');
  }, []);

  const hideCancelDepotModal = useCallback(() => {
    setModal(null);
  }, []);

  useMakeElementsSticky({
    actionLayoutRef: mode === 'map-vehicles' ? actionLayoutRef : undefined,
  });

  const loadingText = useLoadingText({
    isLoadingCandidates: vehiclesQuery.isFetching || candidatesHandler.isLoading || factBasicDataQuery.isLoading,
    isLoadingDepot: depotsQuery.isLoading || depotQuery.isLoading,
    isSavingDepot: createAndUpdateDepot.isLoading,
    isLoadingSolution: solutionQuery.isLoading,
    isValidatingInfraPerformanceStep: infraPerformanceStepHandler.isLoading,
  });

  const isLoading =
    depotQuery.isLoading ||
    depotsQuery.isLoading ||
    solutionQuery.isLoading ||
    factBasicDataQuery.isLoading ||
    vehiclesQuery.isLoading ||
    createAndUpdateDepot.isLoading ||
    candidatesHandler.isLoading ||
    infraPerformanceStepHandler.isLoading;

  const isError =
    depotQuery.isError ||
    depotsQuery.isError ||
    solutionQuery.isError ||
    factBasicDataQuery.isError ||
    vehiclesQuery.isError ||
    createAndUpdateDepot.isError ||
    candidatesHandler.isError ||
    infraPerformanceStepHandler.isError;

  const yearsIsIncomplete = useMemo(
    () =>
      Boolean(
        mode === 'depot-infrastructure' &&
          infraPerformanceStepHandler.infraPerformanceSteps?.some((item) => !item.stepIsComplete),
      ),
    [mode, infraPerformanceStepHandler],
  );

  const ctaDisabled = useMemo(
    () => isLoading || (mode === 'depot-infrastructure' && yearsIsIncomplete),
    [mode, isLoading, yearsIsIncomplete],
  );

  const allCandidatesIsSelected = useMemo(
    () => selectedCandidates.length === candidatesHandler.filteredCandidates?.length,
    [selectedCandidates, candidatesHandler],
  );

  if (isError404(depotQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <div className={classNames(classes['depot-form'], 'tds-container')}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {modal === 'cancel-depot' && (
        <Modal
          size="xs"
          header="Confirm cancellation"
          variant="primary"
          ctaConfirmText="Continue setup"
          cancelText="Cancel setup"
          ctaConfirmSubmit={hideCancelDepotModal}
          onClose={onCancelDepotForm}
        >
          Are you sure you want to cancel setting up this depot? Your current progress will not be saved.
        </Modal>
      )}
      {['setup-depot', 'update-depot'].includes(mode) && (
        <ErrorBoundary>
          <SetupAndUpdateForm
            mode={mode}
            solutionId={solutionId}
            depotId={depotId}
            depot={depotQuery.data}
            solutionQueryIsSuccess={solutionQuery.isSuccess}
            depotName={depotName}
            setDepotName={setDepotName}
            depotMaxPowerCapacity={depotMaxPowerCapacity}
            setDepotMaxPowerCapacity={setDepotMaxPowerCapacity}
            depotFutureMaxPowerCapacity={depotFutureMaxPowerCapacity}
            setDepotFutureMaxPowerCapacity={setDepotFutureMaxPowerCapacity}
            depotBasePowerDemand={depotBasePowerDemand}
            setDepotBasePowerDemand={setDepotBasePowerDemand}
            submitOnEnterKey={createAndUpdateDepot.submitOnEnterKey}
            onSetupDepot={createAndUpdateDepot.onSetupDepot}
            onUpdateDepot={createAndUpdateDepot.onUpdateDepot}
            isLoading={isLoading}
            onCancelDepotForm={showSaveAndExitModal}
          />
        </ErrorBoundary>
      )}
      {stepInfo && (
        <ErrorBoundary>
          <ProgressBar progressPercent={progressPercent} />
          <div className={classNames(classes.header, 'mt-spacing-52')}>
            <InfraPerformanceStepInfo header={stepInfo.header} description={stepInfo.description} />
            {yearsIsIncomplete && <Tooltip elRef={createDepotRef}>Complete all years to continue</Tooltip>}
            <div className={classes.navigation}>
              <Button
                ref={createDepotRef}
                text={mode === 'map-vehicles' ? 'Next' : 'Create depot'}
                icon="arrow_right"
                disabled={ctaDisabled}
                onClick={mode === 'map-vehicles' ? activateDepotInfrastructureMode : createAndUpdateDepot.onCreateDepot}
                dataTestid={
                  mode === 'map-vehicles' ? 'button-next-step-in-depot-wizard' : 'button-create-depot-in-depot-wizard'
                }
              />
              <Button onClick={showSaveAndExitModal} text="Cancel" variant="secondary" />
            </div>
          </div>
        </ErrorBoundary>
      )}
      {mode === 'map-vehicles' && (
        <ErrorBoundary>
          <ActionLayout
            actionLayoutRef={actionLayoutRef}
            className="mt-spacing-52"
            left={
              <div className="flex items-center">
                <TotalCount
                  count={selectedCandidates.length}
                  entityName={`VEHICLE${candidatesPluralUppercase} SELECTED`}
                  autoPlural={false}
                />
                <Checkbox
                  label="Select All"
                  value="select-all"
                  className="ml-spacing-16"
                  onChange={handleSelectAllExisting}
                  checked={allCandidatesIsSelected}
                />
              </div>
            }
            right={<CandidatesFilterToggle candidatesHandler={candidatesHandler} />}
            below={<CandidatesFilterSelector candidatesHandler={candidatesHandler} />}
          />
          <Candidates
            solutionId={solutionId}
            handleSelectAll={handleSelectAllShown}
            handleSelectRow={handleSelectRow}
            candidatesHandler={candidatesHandler}
            vehicleSettingsHandler={vehicleSettingsHandler}
            actionMode="multiselect"
            dataTestid="table-map-vehicles-in-depot-wizard"
            enableYearSelectorForChargingEvents={false}
          />
        </ErrorBoundary>
      )}
      {mode === 'depot-infrastructure' && infraPerformanceStepHandler.validateDepotSetupSuccess && (
        <ErrorBoundary>
          <div className="mt-spacing-52">
            <InlineTabs
              tabs={tabs}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              tabsStyle="circle"
              modeVariant="secondary"
              extraClassName="inline-tabs-buttons"
            />
            {infraPerformanceStepHandler.infraPerformanceStep && (
              <InfraPerformanceStep
                hasRightColumnTopMargin
                depotYear={depotYear}
                infraPerformanceStepHandler={infraPerformanceStepHandler}
                chargingSolutions={factBasicDataQuery.data?.ChargingSolutions}
                onNextYear={tabIndex + 1 < tabs.length ? onNextYear : undefined}
              />
            )}
          </div>
        </ErrorBoundary>
      )}
    </div>
  );
};

export default DepotForm;
