import classes from './style.module.scss';

interface Props {
  header: string;
  count: number;
}

const CountBox = ({ header, count }: Props) => (
  <section className={classes['count-box']}>
    <div>{header}</div>
    <h2 className="tds-headline-01">{count}</h2>
  </section>
);

export default CountBox;
