import CandidatesTable from './Table';
import LoadMore from 'common/components/LoadMore';
import { CandidateHandler } from './utils/useCandidatesHandler';
import { CandidateTabs } from './Table/Detail';
import { OnSelectRow } from '@optimization/ssi-common';
import { ListVehicleRowItem, VehicleSettingsHandler } from '@optimization/sa-common';

export const CANDIDATES_PER_PAGE = 12;

interface Props {
  className?: string;
  depotYear?: string;
  solutionId: string;
  candidatesHandler: CandidateHandler;
  vehicleSettingsHandler: VehicleSettingsHandler;
  actionMode?: 'multiselect' | 'expandable-rows';
  itemsPerPage?: number;
  dataTestid?: string;
  expandVehicleId?: number;
  candidateTabDefault?: CandidateTabs;
  enableYearSelectorForChargingEvents?: boolean;
  performanceStepDropdownReadonly?: boolean;
  handleSelectAll?: (checked: boolean) => void;
  handleSelectRow?: OnSelectRow<ListVehicleRowItem>;
}

const Candidates = ({
  className,
  depotYear,
  solutionId,
  candidatesHandler,
  vehicleSettingsHandler,
  actionMode = 'expandable-rows',
  itemsPerPage = CANDIDATES_PER_PAGE,
  dataTestid,
  expandVehicleId,
  candidateTabDefault,
  enableYearSelectorForChargingEvents = true,
  performanceStepDropdownReadonly = false,
  handleSelectAll,
  handleSelectRow,
}: Props) =>
  candidatesHandler.splicedCandidates ? (
    <div className={className}>
      <CandidatesTable
        depotYear={depotYear}
        solutionId={solutionId}
        vehicles={candidatesHandler.splicedCandidates}
        selectedCandidates={candidatesHandler.selectedCandidates}
        actionMode={actionMode}
        dataTestid={dataTestid}
        candidateTabDefault={candidateTabDefault}
        handleSelectAll={handleSelectAll}
        handleSelectRow={handleSelectRow}
        setIsWinter={vehicleSettingsHandler.setIsWinter}
        setIsSoH80={vehicleSettingsHandler.setIsSoH80}
        setShowTopUp={vehicleSettingsHandler.setShowTopUp}
        vehicleSettingsById={vehicleSettingsHandler.vehicleSettingsById}
        performanceStepDropdownReadonly={performanceStepDropdownReadonly}
        enableYearSelectorForChargingEvents={enableYearSelectorForChargingEvents}
        expandVehicleId={expandVehicleId || candidatesHandler.locationStateVehicleId}
      />
      <LoadMore
        contentName="Vehicles"
        itemsCount={candidatesHandler.splicedCandidates?.length}
        totalCount={candidatesHandler.filteredCandidates?.length}
        itemsPerPage={itemsPerPage}
        setItemsCount={candidatesHandler.setVehiclesCount}
      />
    </div>
  ) : null;

export default Candidates;
