import classes from './style.module.scss';
import classNames from 'classnames';
import EnergyCostsFields from './utils/EnergyCostsFields';
import GeneralInfoFields from './utils/GeneralInfoFields';
import OperationalCostsFields from './utils/OperationalCostsFields';
import ScrollProgressBar from './utils/ScrollProgressBar';
import StepInfo from './utils/StepInfo';
import useLoadingText from 'common/hooks/useLoadingText';
import VehicleCostsFields from './utils/VehicleCostsFields';
import { Button, Modal } from '@optimization/ssi-common';
import { energyCostsInfo, generalInfo, operationalCostsInfo, vehicleCostsInfo } from 'app/config/tco';
import { fromCompareOptions } from './utils/GeneralInfoFields';
import { Loading, useToast } from '@optimization/ssi-common';
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useElementController } from 'app/context/ElementControllerContext';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useUpdateVehicleMutation } from 'app/services/solution';
import {
  ChartTooltip,
  FinancialEvaluationToCosts,
  FinancialEvaluationFromCosts,
  VehicleEnhanced,
} from '@optimization/sa-common';

const initialFormValues: FormValues = {
  currency: 'EUR',
  toInsuranceCost: '0',
  toTaxesCost: '0',
  toRoadToll: '0',
  toMaintenanceCost: '0',
  toCompare: 'BEV',
  toEnergyCost: '0',
  toFinanceType: 'leasing',
  toVehiclePrice: '20000',
  toInterestRate: '0',
  toResidual: '4000',
  toGovernmentSubsidies: '0',
  fromInsuranceCost: '0',
  fromTaxesCost: '0',
  fromRoadToll: '0',
  fromMaintenanceCost: '0',
  fromAdBlueCost: '0',
  fromAdBlueConsumption: '0',
  fromCompare: '',
  fromEnergyCost: '0',
  fromEnergyConsumption: '12',
  fromFinanceType: 'leasing',
  fromVehiclePrice: '30000',
  fromInterestRate: '0',
  fromResidual: '10000',
  fromGovernmentSubsidies: '0',
};

export type Mode = 'setup-evaluation' | 'edit-evaluation' | 'copy-settings';

export interface FormValues {
  currency: string;
  toInsuranceCost: string;
  toTaxesCost: string;
  toRoadToll: string;
  toMaintenanceCost: string;
  toCompare: string;
  toEnergyCost: string;
  toFinanceType: string;
  toVehiclePrice: string;
  toInterestRate: string;
  toResidual: string;
  toGovernmentSubsidies: string;
  fromInsuranceCost: string;
  fromTaxesCost: string;
  fromRoadToll: string;
  fromMaintenanceCost: string;
  fromAdBlueCost: string;
  fromAdBlueConsumption: string;
  fromCompare: string;
  fromEnergyCost: string;
  fromEnergyConsumption: string;
  fromFinanceType: string;
  fromVehiclePrice: string;
  fromInterestRate: string;
  fromResidual: string;
  fromGovernmentSubsidies: string;
}

export type SetFormValue = (attribute: string, value: string) => void;

type ModalType = 'save-and-exit' | 'clear-all-fields';

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
  depotId?: string;
  cancelSetupEvaluation: () => void;
  setEvaluationIsDone: ({ vehicleId, isDone }: { vehicleId: number; isDone: boolean }) => void;
}

const SetupEvaluation = ({ solutionId, vehicle, cancelSetupEvaluation, setEvaluationIsDone }: Props) => {
  const showToast = useToast();

  const elementController = useElementController();

  const tooltipId = useId();

  const scrollProgressBarRef = useRef<HTMLDivElement | null>(null);
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const createDepotRef = useRef<HTMLDivElement | null>(null);

  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const [formValues, setFormValues] = useState<FormValues>({
    ...initialFormValues,
  });

  const setFormValue = useCallback(
    (attribute: string, value: string) => {
      setFormValues((prev) => ({ ...prev, [attribute]: value }));
    },
    [setFormValues],
  );

  const [modal, setModal] = useState<ModalType | null>(null);

  useEffect(() => {
    if (elementController) {
      elementController.setEnableSidebar(false);
    }

    return () => {
      if (elementController) {
        elementController.setEnableSidebar(true);
      }
    };
  }, [elementController]);

  const showSaveAndExitModal = useCallback(() => {
    setModal('save-and-exit');
  }, []);

  const saveAndExit = useCallback(() => {
    updateVehicle({
      solutionId,
      vehicle: {
        ...vehicle,
      },
    });
  }, [solutionId, vehicle, updateVehicle]);

  useEffect(() => {
    if (updateVehicleState.isSuccess) {
      showToast({
        header: 'Financial evaluation saved successfully',
        dataTestid: 'toast-saved-financial-evaluation',
      });

      setEvaluationIsDone({ vehicleId: vehicle.Id, isDone: true });
    }
  }, [updateVehicleState, vehicle.Id, setEvaluationIsDone, showToast]);

  const showClearAllFieldsModal = useCallback(() => {
    setModal('clear-all-fields');
  }, []);

  const hideModal = useCallback(() => {
    setModal(null);
  }, []);

  const clearAllFields = useCallback(() => {
    hideModal();

    setFormValues({ ...initialFormValues });

    showToast({
      header: 'All settings cleared',
    });
  }, [hideModal, setFormValues, showToast]);

  useMakeElementsSticky({
    scrollProgressBarRef,
    summaryRef,
  });

  const fromCostsHeader = useMemo(() => {
    if (formValues.fromCompare) {
      const fromOption = fromCompareOptions.find((item) => item.value === formValues.fromCompare);

      if (fromOption) {
        return fromOption.name;
      }
    }

    return '...';
  }, [formValues]);

  const toTotalCosts = useMemo(() => {
    return (
      Number(formValues.toVehiclePrice) +
      Number(formValues.toInterestRate) +
      Number(formValues.toResidual) +
      Number(formValues.toGovernmentSubsidies) +
      Number(formValues.toEnergyCost) +
      Number(formValues.toInsuranceCost) +
      Number(formValues.toTaxesCost) +
      Number(formValues.toRoadToll) +
      Number(formValues.toMaintenanceCost) +
      18310
    );
  }, [formValues]);

  const fromTotalCosts = useMemo(() => {
    return (
      Number(formValues.fromVehiclePrice) +
      Number(formValues.fromInterestRate) +
      Number(formValues.fromResidual) +
      Number(formValues.fromGovernmentSubsidies) +
      Number(formValues.fromEnergyCost) +
      Number(formValues.fromEnergyConsumption) +
      Number(formValues.fromAdBlueCost) +
      Number(formValues.fromAdBlueConsumption) +
      Number(formValues.fromInsuranceCost) +
      Number(formValues.fromTaxesCost) +
      Number(formValues.fromRoadToll) +
      Number(formValues.fromMaintenanceCost) +
      21040
    );
  }, [formValues]);

  const isLoading = updateVehicleState.isLoading;
  const isError = updateVehicleState.isError;

  const loadingText = useLoadingText({ isSavingEvaluation: isLoading });

  return (
    <div className={classNames(classes['setup-evaluation'], 'tds-container')}>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {modal === 'save-and-exit' && (
        <Modal
          size="xs"
          header="Save and exit"
          variant="primary"
          disabled={isLoading}
          ctaConfirmSubmitDataTestid="button-save-financial-evaluation"
          ctaConfirmText="Save and exit"
          ctaConfirmSubmit={saveAndExit}
          onClose={hideModal}
        >
          Are you sure you want to exit? Any unsaved changes will be saved.
        </Modal>
      )}
      {modal === 'clear-all-fields' && (
        <Modal
          size="xs"
          header="Clear all fields"
          variant="danger"
          ctaConfirmText="Remove values"
          ctaConfirmSubmit={clearAllFields}
          onClose={hideModal}
        >
          Are you sure you want to remove all added values from the fields?
        </Modal>
      )}
      <ScrollProgressBar scrollProgressBarRef={scrollProgressBarRef} />
      <ChartTooltip tooltipId={tooltipId} hidden absolute />
      <div className={classes.summary} ref={summaryRef}>
        <div className={classes.inner}>
          <div className={classes.navigation}>
            <Button
              ref={createDepotRef}
              text="Save and exit"
              disabled={isLoading}
              dataTestid="button-save-and-exit-financial-evaluation"
              onClick={showSaveAndExitModal}
            />
            <Button text="Cancel" variant="secondary" disabled={isLoading} onClick={cancelSetupEvaluation} />
          </div>
          <div className={classes.costs}>
            <FinancialEvaluationToCosts
              vehicleCosts={65}
              energyCosts={30}
              operationalCosts={5}
              totalCost={toTotalCosts}
              isEmpty={false}
              tooltipId={tooltipId}
            />
            <FinancialEvaluationFromCosts
              vehicleCosts={70}
              energyCosts={20}
              operationalCosts={10}
              totalCost={fromTotalCosts}
              isEmpty={!Boolean(formValues.fromCompare)}
              header={fromCostsHeader}
              tooltipId={tooltipId}
            />
          </div>
          <button
            onClick={showClearAllFieldsModal}
            className={classNames('reset-button-styles', classes['clear-all-fields'])}
          >
            Clear all fields
          </button>
        </div>
      </div>
      <div className={classNames('mt-spacing-48', classes.steps)}>
        <div className={classes.step}>
          <StepInfo header={generalInfo.header} description={generalInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <GeneralInfoFields
                disabled={isLoading}
                vehicle={vehicle}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={vehicleCostsInfo.header} description={vehicleCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <VehicleCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={energyCostsInfo.header} description={energyCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <EnergyCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                vehicle={vehicle}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
        <div className={classes.step}>
          <StepInfo header={operationalCostsInfo.header} description={operationalCostsInfo.description} />
          <div className={classes.body}>
            <div className={classes.form}>
              <OperationalCostsFields
                disabled={isLoading}
                fromCostsHeader={fromCostsHeader}
                formValues={formValues}
                setFormValue={setFormValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupEvaluation;
