import getMaxKey from './getMaxKey';
import increaseValue from './increaseValue';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useSuggestedWheelConfiguration = ({
  vehicles,
  wheelConfigurationList,
}: {
  vehicles: ListVehicleTransformed[];
  wheelConfigurationList: string[];
}) =>
  useMemo(() => {
    let result = '';

    if (vehicles.length) {
      const engineGearboxCount = vehicles.reduce<{
        [key: string]: number;
      }>((acc, vehicle) => {
        const wheelConfiguration = vehicle.WheelConfiguration;

        return increaseValue({
          acc,
          key: wheelConfiguration,
          list: wheelConfigurationList,
        });
      }, {});

      result = getMaxKey({ countByName: engineGearboxCount });
    }

    return result;
  }, [vehicles, wheelConfigurationList]);

export default useSuggestedWheelConfiguration;
