import CandidatesTable from './CandidatesTable';
import classes from './style.module.scss';
import classNames from 'classnames';
import CountBox from './utils/CountBox';
import DepotsGrid from './DepotsGrid';
import ErrorBoundary from 'src/common/components/ErrorBoundary';
import HelmetTitle from 'common/components/HelmetTitle';
import Messages from './utils/Messages';
import useDepotsWithWarning from './DepotsGrid/useDepotsWithWarning';
import useMessages from './utils/Messages/useMessages';
import useNotFeasableVehicles from './CandidatesTable/useNotFeasableVehicles';
import { TdsMessage } from '@scania/tegel-react';
import { useEnhancedListVehicles } from '@optimization/sa-common';
import { useGetDepotsQuery, useGetFactBasicDataQuery, useGetVehiclesQuery } from 'app/services/solution';
import { useTour } from 'app/context/TourContext';

interface Props {
  solutionId: string;
}

const Actions = ({ solutionId }: Props) => {
  const factBasicDataQuery = useGetFactBasicDataQuery();
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);

  const tour = useTour();

  const enhancedListVehicles = useEnhancedListVehicles({
    vehicles: vehiclesQuery.data,
    factBasicDataQueryData: factBasicDataQuery.data,
  });

  const notFeasibleVehicles = useNotFeasableVehicles({ enhancedListVehicles });
  const depotsWithWarning = useDepotsWithWarning({ depots: depotsQuery.data });

  const messages = useMessages({
    depots: depotsQuery.data,
    depotsIsReady: depotsQuery.isSuccess,
    depotsWithWarning,
    notFeasibleVehicles,
    vehicles: vehiclesQuery.data,
    vehiclesIsReady: vehiclesQuery.isSuccess,
  });

  const isSuccess = vehiclesQuery.isSuccess && depotsQuery.isSuccess;

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Home']} />
      {isSuccess && (
        <>
          <div className={classNames(classes.body, 'mt-spacing-64')}>
            <ErrorBoundary>
              <div className={classes['count-and-messages']}>
                <div className={classes['count-wrapper']}>
                  <CountBox header="Vehicles" count={vehiclesQuery.data.length} />
                  <CountBox header="Depots" count={depotsQuery.data.length} />
                </div>
                <section className={classes.messages}>
                  <h2 className="tds-headline-04">Actions</h2>
                  {messages.vehicleMessages || messages.depotMessages ? (
                    <>
                      <Messages header="Vehicle candidates" messages={messages.vehicleMessages} />
                      <Messages header="Depot management" messages={messages.depotMessages} />
                    </>
                  ) : (
                    <TdsMessage header="No action needed" variant="success" />
                  )}
                </section>
              </div>
            </ErrorBoundary>
            <div className={classes['updates-wrapper']}>
              <section className={classes.updates}>
                {notFeasibleVehicles || depotsWithWarning ? (
                  <>
                    <h2 className="tds-headline-04">Required updates</h2>
                    <div className={classes['entity-groups']}>
                      {notFeasibleVehicles && (
                        <ErrorBoundary>
                          <CandidatesTable
                            solutionId={solutionId}
                            notFeasibleVehicles={notFeasibleVehicles}
                            totalVehicles={vehiclesQuery.data.length}
                          />
                        </ErrorBoundary>
                      )}
                      {depotsWithWarning && (
                        <ErrorBoundary>
                          <DepotsGrid solutionId={solutionId} depotsWithWarning={depotsWithWarning} />
                        </ErrorBoundary>
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    <h2 className="tds-headline-04">Required updates</h2>
                    <p className="tds-body-02 mt-spacing-4">
                      {messages.vehicleMessages || messages.depotMessages
                        ? 'Complete the actions'
                        : 'All current actions are completed'}
                    </p>
                  </div>
                )}
              </section>
            </div>
          </div>
          {tour && tour.card}
        </>
      )}
    </>
  );
};

export default Actions;
