import FiltersToggle from 'common/components/Filters/Toggle';
import sortOptions from '../utils/sortOptions';
import { CandidateHandler } from 'features/candidate/Candidates/utils/useCandidatesHandler';

interface Props {
  candidatesHandler: CandidateHandler;
  className?: string;
}

const CandidatesFilterToggle = ({ candidatesHandler, className }: Props) =>
  candidatesHandler.isSuccess ? (
    <FiltersToggle
      className={className}
      sortType={candidatesHandler.sortType}
      sortDirection={candidatesHandler.sortDirection}
      sortOptions={sortOptions}
      activeAction={candidatesHandler.filtersHandler.activeFiltersAction}
      toggleFilters={candidatesHandler.filtersHandler.toggleFilters}
      toggleSortBy={candidatesHandler.filtersHandler.toggleSortBy}
      toggleSortDirection={candidatesHandler.filtersHandler.toggleSortDirection}
      selectedFilters={candidatesHandler.selectedFilters}
      onRemoveFilter={candidatesHandler.onRemoveFilter}
      onRemoveFilters={candidatesHandler.onRemoveFilters}
      dataTestid="candidates-filter-toggle"
    />
  ) : null;

export default CandidatesFilterToggle;
