import { KeyedString } from '@optimization/ssi-common';
import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const useVehicleNamesByChassisNumbersV2 = (enhancedVehicles: ListVehicleEnhanced[]): KeyedString =>
  useMemo(
    () =>
      enhancedVehicles
        .filter((enhancedVehicle) => enhancedVehicle.ChassisNumber && enhancedVehicle.Name)
        .reduce(
          (acc, enhancedVehicle) => ({
            ...acc,
            [enhancedVehicle.ChassisNumber || '']: enhancedVehicle.Name,
          }),
          {},
        ),
    [enhancedVehicles],
  );

export default useVehicleNamesByChassisNumbersV2;
