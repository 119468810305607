import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { FormValues, SetFormValue } from '../..';
import { TextField } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  fromCostsHeader: string;
  formValues: FormValues;
  setFormValue: SetFormValue;
}

const OperationalCostsFields = ({ className, disabled, fromCostsHeader, formValues, setFormValue }: Props) => {
  const onChangeBevInsuranceCost = useOnChangeFieldValue({
    attribute: 'toInsuranceCost',
    setFormValue,
  });

  const onChangeBevTaxesCost = useOnChangeFieldValue({
    attribute: 'toTaxesCost',
    setFormValue,
  });

  const onChangeBevRoadToll = useOnChangeFieldValue({
    attribute: 'toRoadToll',
    setFormValue,
  });

  const onChangeBevMaintenance = useOnChangeFieldValue({
    attribute: 'toMaintenanceCost',
    setFormValue,
  });

  const onChangeDieselInsuranceCost = useOnChangeFieldValue({
    attribute: 'fromInsuranceCost',
    setFormValue,
  });

  const onChangeDieselTaxesCost = useOnChangeFieldValue({
    attribute: 'fromTaxesCost',
    setFormValue,
  });

  const onChangeDieselRoadToll = useOnChangeFieldValue({
    attribute: 'fromRoadToll',
    setFormValue,
  });

  const onChangeDieselMaintenance = useOnChangeFieldValue({
    attribute: 'fromMaintenanceCost',
    setFormValue,
  });

  const suffixText = useMemo(() => (window.innerWidth >= 608 ? 'EUR/month' : 'EUR/mon'), []);

  return (
    <div className={classNames(classes['operational-costs-fields'], className)}>
      <div className={classNames(classes['header-1'], 'tds-detail-04')}>BEV</div>
      <div className={classNames(classes['header-2'], 'tds-detail-04')}>{fromCostsHeader}</div>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Insurance</div>
      <TextField
        className={classes['field-1-1']}
        type="number"
        value={formValues.toInsuranceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevInsuranceCost}
      />
      <TextField
        className={classes['field-1-2']}
        type="number"
        value={formValues.fromInsuranceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselInsuranceCost}
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>TaxesCost</div>
      <TextField
        className={classes['field-2-1']}
        type="number"
        value={formValues.toTaxesCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevTaxesCost}
      />
      <TextField
        className={classes['field-2-2']}
        type="number"
        value={formValues.fromTaxesCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselTaxesCost}
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Road toll</div>
      <TextField
        className={classes['field-3-1']}
        type="number"
        value={formValues.toRoadToll}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevRoadToll}
      />
      <TextField
        className={classes['field-3-2']}
        type="number"
        value={formValues.fromRoadToll}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselRoadToll}
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Maintenance</div>
      <TextField
        className={classes['field-4-1']}
        type="number"
        value={formValues.toMaintenanceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevMaintenance}
      />
      <TextField
        className={classes['field-4-2']}
        type="number"
        value={formValues.fromMaintenanceCost}
        size="lg"
        suffixText={suffixText}
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselMaintenance}
      />
    </div>
  );
};

export default OperationalCostsFields;
