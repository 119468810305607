import classes from './style.module.scss';
import classNames from 'classnames';
import { GetStopsParams } from '..';
import { StopsMetadataResponseVM } from '@optimization/sa-common';
import { TdsDivider, TdsIcon } from '@scania/tegel-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  formatDateInYearMonthDay,
  isValidDate,
  ButtonAlt,
  Accordion,
  Checkbox,
  Datetime,
  Slider,
  TextField,
  LayersIcon,
} from '@optimization/ssi-common';

interface Props {
  showSettings: boolean;
  showTrips: boolean;
  showStops: boolean;
  getStopsParams: GetStopsParams;
  isFullScreen: boolean;
  stopsMetadata: StopsMetadataResponseVM;
  getStopsCount: number;
  onShowSettings: () => void;
  onHideSettings: () => void;
  onChangeTrips: (checked: boolean) => void;
  onChangeStops: (checked: boolean) => void;
  getStopsDelayed: (getStopsParams?: Object) => void;
}

const Settings = ({
  showSettings,
  showTrips,
  showStops,
  getStopsParams,
  isFullScreen,
  stopsMetadata,
  getStopsCount,
  onShowSettings,
  onHideSettings,
  onChangeTrips,
  onChangeStops,
  getStopsDelayed,
}: Props) => {
  const [userHasChangedDuration, setUserHasChangedDuration] = useState(false);

  const [minDurationValue, setMinDurationValue] = useState(getStopsParams.minDuration);

  const [maxDurationValue, setMaxDurationValue] = useState(getStopsParams.maxDuration);

  const minDuration = useMemo(() => parseInt(`${stopsMetadata.MinDuration / 60}`), [stopsMetadata]);

  const maxDuration = useMemo(() => parseInt(`${stopsMetadata.MaxDuration / 60}`), [stopsMetadata]);

  const isMinDurationInsideBounds = useMemo(
    () =>
      minDurationValue &&
      maxDurationValue &&
      minDuration &&
      maxDuration &&
      Number(minDurationValue) >= minDuration &&
      Number(minDurationValue) <= maxDuration &&
      Number(minDurationValue) < Number(maxDurationValue),
    [minDurationValue, maxDurationValue, minDuration, maxDuration],
  );

  const isMaxDurationInsideBounds = useMemo(
    () =>
      minDurationValue &&
      maxDurationValue &&
      minDuration &&
      maxDuration &&
      Number(maxDurationValue) >= minDuration &&
      Number(maxDurationValue) <= maxDuration &&
      Number(maxDurationValue) > Number(minDurationValue),
    [maxDurationValue, minDurationValue, minDuration, maxDuration],
  );

  useEffect(() => {
    setMinDurationValue(getStopsParams.minDuration);
    setMaxDurationValue(getStopsParams.maxDuration);
  }, [getStopsParams, getStopsCount]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userHasChangedDuration && isMinDurationInsideBounds && isMaxDurationInsideBounds) {
        getStopsDelayed({
          minDuration: minDurationValue,
          maxDuration: maxDurationValue,
        });
      }
    }, 600);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [
    isMinDurationInsideBounds,
    isMaxDurationInsideBounds,
    minDurationValue,
    maxDurationValue,
    userHasChangedDuration,
    getStopsDelayed,
  ]);

  const onChangeDate = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (value) {
        const date = new Date(value);

        if (isValidDate(date) && date.getFullYear() > 1999 && date.getFullYear() < 10000) {
          getStopsDelayed({ [key]: value });
        }
      }
    },
    [getStopsDelayed],
  );

  const onChangeStartDate = useCallback(
    (startDate: string) => {
      onChangeDate({
        key: 'startDate',
        value: startDate,
      });
    },
    [onChangeDate],
  );

  const onChangeEndDate = useCallback(
    (endDate: string) => {
      onChangeDate({
        key: 'endDate',
        value: endDate,
      });
    },
    [onChangeDate],
  );

  const onChangeMinDuration = useCallback((value: string) => {
    setMinDurationValue(value);
    setUserHasChangedDuration(true);
  }, []);

  const onChangeMaxDuration = useCallback((value: string) => {
    setMaxDurationValue(value);
    setUserHasChangedDuration(true);
  }, []);

  return (
    <>
      <div
        className={classNames(classes.settings, showSettings ? classes.visible : classes.hidden, {
          [classes['settings-fullscreen']]: isFullScreen,
        })}
      >
        <div className={classNames('cursor-pointer', classes.close)}>
          <div>Settings</div>
          <ButtonAlt onClick={onHideSettings} tdsIcon="cross" />
        </div>
        <Accordion
          hideLastBorder
          items={[
            {
              header: (
                <div className="flex items-center">
                  <LayersIcon />
                  <div className="ml-spacing-12">Layers</div>
                </div>
              ),
              children: (
                <div className="ml-spacing-32 mt-spacing-8">
                  <Checkbox checked={showStops} label="Stop locations" value="stops" onChange={onChangeStops} />
                  <Checkbox
                    checked={showTrips}
                    label="Trips"
                    value="trips"
                    className="mt-spacing-32"
                    onChange={onChangeTrips}
                  />
                  <Slider
                    className="mb-negative-spacing-16"
                    label="Resolution"
                    min={stopsMetadata.MinResolution.toString()}
                    max={stopsMetadata.MaxResolution.toString()}
                    value={getStopsParams.resolution}
                    onChange={(resolution) => getStopsDelayed({ resolution })}
                  />
                </div>
              ),
              expanded: true,
            },
            {
              header: (
                <div className="flex items-center">
                  <TdsIcon size="24px" name="doner" />
                  <div className="ml-spacing-8">Filter</div>
                </div>
              ),
              children: (
                <div className={classes.filter}>
                  <div>
                    <p>Select time window</p>
                    <div className="flex gap-4 tds-u-mt2">
                      <Datetime
                        label="Start date"
                        className="w-full"
                        noMinWidth
                        value={getStopsParams.startDate}
                        onChange={onChangeStartDate}
                        min={formatDateInYearMonthDay(new Date(stopsMetadata.OldestDateTime))}
                        max={formatDateInYearMonthDay(new Date(stopsMetadata.NewestDateTime))}
                      />
                      <Datetime
                        label="End date"
                        className="w-full"
                        noMinWidth
                        value={getStopsParams.endDate}
                        onChange={onChangeEndDate}
                        min={formatDateInYearMonthDay(new Date(stopsMetadata.OldestDateTime))}
                        max={formatDateInYearMonthDay(new Date(stopsMetadata.NewestDateTime))}
                      />
                    </div>
                  </div>
                  <div className={classNames('tds-u-mt2', 'tds-u-mb2', classes.divider)}>
                    <TdsDivider />
                  </div>
                  <div>
                    <p>Select stop duration </p>
                    <div className="flex gap-4 tds-u-mt2">
                      <div className="w-full">
                        <TextField
                          label="Minimum duration"
                          labelPosition="outside"
                          helper="In minutes"
                          type="number"
                          noMinWidth
                          value={getStopsParams.minDuration}
                          onChange={onChangeMinDuration}
                        />
                        <div
                          className={classNames(
                            userHasChangedDuration &&
                              minDuration &&
                              minDurationValue &&
                              Number(minDurationValue) < minDuration
                              ? 'tds-text-red-400'
                              : 'tds-text-grey-600',
                            classes.limit,
                          )}
                        >
                          Minimum: {minDuration}
                        </div>
                      </div>
                      <div className="w-full">
                        <TextField
                          label="Maximum duration"
                          labelPosition="outside"
                          helper="In minutes"
                          type="number"
                          noMinWidth
                          value={getStopsParams.maxDuration}
                          onChange={onChangeMaxDuration}
                        />
                        <div
                          className={classNames(
                            userHasChangedDuration &&
                              maxDuration &&
                              maxDurationValue &&
                              Number(maxDurationValue) > maxDuration
                              ? 'tds-text-red-400'
                              : 'tds-text-grey-600',
                            classes.limit,
                          )}
                        >
                          Maximum: {maxDuration}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ),
              expanded: true,
            },
          ]}
        />
      </div>
      <ButtonAlt
        hoverBorder
        tdsIcon="filters"
        tdsIconSize={24}
        onClick={onShowSettings}
        className={isFullScreen ? classes['toggle-settings-fullscreen'] : classes['toggle-settings-normal']}
      />
    </>
  );
};

export default Settings;
