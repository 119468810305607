import classNames from 'classnames';
import PtoDevicesSelector from 'features/candidate/Pto/DevicesSelector';
import usePtoHandler from './usePtoHandler';
import { invariant } from '@optimization/ssi-common';
import { PtoDevices, VehicleEnhanced } from '@optimization/sa-common';
import { useParams } from 'react-router-dom';

interface Props {
  vehicle: VehicleEnhanced;
  ptoDevices: PtoDevices;
}

const Pto = ({ vehicle, ptoDevices }: Props) => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const disabled = vehicle.enhanced.hasChargingEvents;

  const ptoHandler = usePtoHandler({
    solutionId,
    vehicle,
    ptoDevices,
    disabled,
  });

  return (
    <>
      <div className="min-h-330 overflow-x-hidden">
        <PtoDevicesSelector
          dataTestid="dropdown-candidate-detail-pto-devices"
          openDirection="down"
          ecItems={ptoHandler.ecItems}
          ptoDevices={ptoDevices}
          onChangePtoDevices={ptoHandler.onChangePtoDevices}
          disabled={disabled}
        />
        {ptoHandler.dropdownEcItems.map((ecItem) => (
          <div key={ecItem.Type} className="tds-u-mt2">
            {ptoHandler.getPtoField(ecItem.Type)}
          </div>
        ))}
      </div>
      <div>
        {ptoHandler.checkboxEcItems.map((ecItem, index) => (
          <div key={ecItem.Type} className={classNames({ 'tds-u-mt2': Boolean(index) })}>
            {ptoHandler.getPtoField(ecItem.Type)}
          </div>
        ))}
      </div>
    </>
  );
};

export default Pto;
