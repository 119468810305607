import classes from './style.module.scss';
import classNames from 'classnames';
import Summary from './Summary';
import { Button, Spinner, TextArea, usePlural, useToast } from '@optimization/ssi-common';
import { TdsMessage } from '@scania/tegel-react';
import { TimeWindow } from '@optimization/sa-common';
import { useAddChassisToSolutionMutation, useValidateChassisMutation } from 'app/services/solution';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  solutionId: string;
  drawerWidth: number;
  deactivateAddVehicleDrawer: () => void;
}

const ScaniaChassis = ({ solutionId, drawerWidth, deactivateAddVehicleDrawer }: Props) => {
  const showToast = useToast();

  const [validateChassisNumber, validateChassisState] = useValidateChassisMutation();
  const [addCandidateToSolution, addCandidateToSolutionState] = useAddChassisToSolutionMutation();

  const [chassisNumber, setChassisNumber] = useState('');

  const chassisNumbers = useMemo(() => {
    const trimedChassisNumber = chassisNumber.replace(/[^0-9\s]/g, '');
    return trimedChassisNumber.split(/\s+/).filter((trimedChassisNumber) => trimedChassisNumber !== '');
  }, [chassisNumber]);

  const evaluateChassisNumber = useCallback(() => {
    validateChassisNumber({
      solutionId,
      ChassisNumbers: chassisNumbers.map(Number),
      TimeWindow: TimeWindow.Lifetime,
    });
  }, [solutionId, chassisNumbers, validateChassisNumber]);

  const handleAddCandidateToSolution = useCallback(() => {
    if (validateChassisState.data?.FoundVehicles) {
      addCandidateToSolution({
        solutionId,
        ChassisNumbers: validateChassisState.data?.FoundVehicles,
        TimeWindow: TimeWindow.Lifetime,
      });
    }
  }, [validateChassisState.data?.FoundVehicles, solutionId, addCandidateToSolution]);

  useEffect(() => {
    if (!addCandidateToSolutionState.isUninitialized && !addCandidateToSolutionState.isLoading) {
      if (addCandidateToSolutionState.isSuccess && validateChassisState.data?.FoundVehicles) {
        showToast({
          header: `${validateChassisState.data.FoundVehicles.length} vehicle candidate${validateChassisState.data?.FoundVehicles.length === 1 ? '' : 's'} added`,
          dataTestid: 'toast-vehicle-candidates-added',
        });
        deactivateAddVehicleDrawer();
      } else {
        showToast({ header: "Couldn't add candidates", variant: 'error' });
      }
    }
  }, [addCandidateToSolutionState, validateChassisState.data?.FoundVehicles, deactivateAddVehicleDrawer, showToast]);

  const foundCandidatesCount = useMemo(
    () => (validateChassisState.data && validateChassisState.data?.FoundVehicles?.length) || 0,
    [validateChassisState.data],
  );

  const foundCandidates = useMemo(() => foundCandidatesCount > 0, [foundCandidatesCount]);

  const foundNoCandidates = useMemo(
    () => validateChassisState.data?.FoundVehicles?.length === 0,
    [validateChassisState.data?.FoundVehicles?.length],
  );

  const foundCandidatesPlural = usePlural({
    count: foundCandidatesCount,
  });

  const isLoading = validateChassisState.isLoading || addCandidateToSolutionState.isLoading;

  return (
    <div className={classNames('px-spacing-16', 'py-spacing-24', classes['scania-chassis'])}>
      {!isLoading && (
        <>
          {validateChassisState.isSuccess && foundCandidates ? (
            <Summary
              chassisNumber={chassisNumber}
              chassisNumbers={chassisNumbers}
              validateResponse={validateChassisState.data}
            />
          ) : (
            <TextArea
              rows={4}
              label="Input Scania chassis numbers"
              labelPosition="outside"
              dataTestid="input-chassis-numbers"
              value={chassisNumber}
              onChange={setChassisNumber}
            />
          )}
          {(foundNoCandidates || validateChassisState.isError) && (
            <div className="mt-spacing-24">
              {validateChassisState.isError && (
                <TdsMessage variant="error" mode-variant="inherit from parent">
                  Something went wrong
                </TdsMessage>
              )}
              {foundNoCandidates && !validateChassisState.isError && (
                <TdsMessage variant="information" mode-variant="inherit from parent">
                  Data missing for all chassis
                </TdsMessage>
              )}
            </div>
          )}
        </>
      )}
      <div className={classes.footer} style={{ width: `${drawerWidth}px` }}>
        {validateChassisState.isLoading || addCandidateToSolutionState.isLoading ? (
          <div className={classes.loading}>
            <p>
              {validateChassisState.isLoading
                ? 'Finding and evaluating chassis'
                : 'Adding vehicle candidates to solution'}
              ...
            </p>
            <Spinner />
          </div>
        ) : (
          <>
            {foundCandidates && validateChassisState.data ? (
              <>
                <div className={classes.summary}>
                  <div>
                    <div className="tds-detail-06">Candidates created</div>
                    <div className="tds-headline-06">
                      {validateChassisState.data.FoundVehicles.length}/{chassisNumbers.length}
                    </div>
                  </div>
                  <div>
                    <div className="tds-detail-06">BEV Product ADAPTED</div>
                    <div className="tds-headline-06">
                      {validateChassisState.data.ExecutableVehicles.length}/
                      {validateChassisState.data.FoundVehicles.length}
                    </div>
                  </div>
                </div>
                <Button
                  text={`Add ${foundCandidatesCount} candidate${foundCandidatesPlural} to solution`}
                  disabled={isLoading}
                  dataTestid="button-add-chassis-to-solution"
                  fullbleed
                  onClick={handleAddCandidateToSolution}
                />
              </>
            ) : (
              <Button
                className={classes.cta}
                dataTestid="button-evaluate-chassis"
                variant={chassisNumber ? 'primary' : 'secondary'}
                text="Evaluate"
                disabled={!chassisNumber || isLoading}
                fullbleed
                onClick={evaluateChassisNumber}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ScaniaChassis;
