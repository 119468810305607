import classes from './style.module.scss';
import { VehicleEnhanced, EnvironmentalImpact } from '@optimization/sa-common';
import { useGetEnvironmentalImpactQuery, useUpdateVehicleMutation } from 'src/app/services/solution';
import { countries, DebouncedTextField, Dropdown, Loading } from '@optimization/ssi-common';
import useLoadingText from 'common/hooks/useLoadingText';
import { useCallback, useMemo } from 'react';

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
}

const EnvironmentalImpactWrapper = ({ solutionId, vehicle }: Props) => {
  const environmentalImpactQuery = useGetEnvironmentalImpactQuery({ solutionId, vehicleId: vehicle.Id });
  const loadingText = useLoadingText({ isLoadingEnvironmentalImpact: environmentalImpactQuery.isLoading });

  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const countryOptions = useMemo(
    () => countries.map((c) => ({ name: c, value: c })).sort((a, b) => a.name.localeCompare(b.name)),
    [],
  );

  const handleCountryChange = useCallback(
    (country: string) => {
      updateVehicle({
        solutionId,
        vehicle: {
          ...vehicle,
          ExecutedVehicleProduct: { ...vehicle.ExecutedVehicleProduct, ComparisonCountryOfOperations: country },
        },
      });
    },
    [vehicle, updateVehicle, solutionId],
  );

  const handleConsumptionChange = useCallback(
    (value: string) => {
      const consumption = parseFloat(value);
      if (!isNaN(consumption)) {
        updateVehicle({
          solutionId,
          vehicle: {
            ...vehicle,
            ExecutedVehicleProduct: { ...vehicle.ExecutedVehicleProduct, ComparisonConsumptionLPer100Km: consumption },
          },
        });
      }
    },
    [vehicle, updateVehicle, solutionId],
  );

  return (
    <div className={classes['env-impact']}>
      <Loading
        isLoading={environmentalImpactQuery.isLoading || updateVehicleState.isLoading}
        isError={environmentalImpactQuery.isError || updateVehicleState.isError}
        loadingText={loadingText}
      />
      {environmentalImpactQuery.data && (
        <div>
          <div className={classes['form-row']}>
            <DebouncedTextField
              disabled={environmentalImpactQuery.data.ReadOnly}
              label={'Consumption (' + environmentalImpactQuery.data.FuelTypeFriendlyString + ')'}
              suffixText={environmentalImpactQuery.data.FuelUnitShort + '/100km'}
              value={environmentalImpactQuery.data.ConsumptionPer100Km.toString()}
              type="number"
              onChange={handleConsumptionChange}
            />
            <Dropdown
              disabled={environmentalImpactQuery.data.ReadOnly}
              label="Country of operation (Energy mix)"
              labelPosition="outside"
              placeholder="Select country"
              options={countryOptions}
              value={environmentalImpactQuery.data.Country}
              onChange={handleCountryChange}
            />
          </div>
          <EnvironmentalImpact environmentalImpact={environmentalImpactQuery.data} />
        </div>
      )}
    </div>
  );
};

export default EnvironmentalImpactWrapper;
