import { ChargingEventType, ChargingEventV2VM } from '@optimization/sa-common';
import { Datum } from 'react-charts';
import { ReactNode, useMemo } from 'react';
import { ValidateChargingEventsHandler } from './useValidateChargingEventsHandler';

export const unreachableChargingEventsMessage: Message = {
  header: 'Unreachable charging events',
  body: 'One or more charging events have been removed due to unreachable km mark',
  variant: 'error',
};

export const slowChargerMissingMessage: Message = {
  header: 'Slow charger missing',
  body: 'The slow-charging event has been converted to use an external charger.',
  variant: 'warning',
};

export const eventStartTimeOccursToLateMessage: Message = {
  header: 'Event occurs too late',
  body: 'Time must be within 24 hours of departure',
  variant: 'warning',
};

export const chargerConnectivetyErrorMessage: Message = {
  header: 'Missing connectivity',
  body: 'One or more charging events have been removed due to missing connectors.',
  variant: 'error',
};

export const overSpeedLimitMessage: Message = {
  header: 'Speed limit exceeded',
  body: 'Unrealistic start time due to selected distance',
  variant: 'error',
};

export const powerNegotiationMessage: Message = {
  header: 'Power negotiation',
  body: 'One or more events have been affected by overlapping charging events.',
  variant: 'warning',
};

export const notEnoughPowerBlocksMessage: Message = {
  header: 'Not enough power modules',
  body: 'One or more charging events has not enough power modules available',
  variant: 'warning',
};

export const notEnoughTimeToChargeMessage: Message = {
  header: 'Not enough time to charge',
  body: 'To reach the desired SoC, extend the duration or add more power / chargers',
  variant: 'warning',
};

export const getChargingEventMessage = ({
  chargingEvent,
  checkOverSpeedLimit = true,
}: {
  chargingEvent: ChargingEventV2VM;
  checkOverSpeedLimit?: boolean;
}): Message | undefined => {
  if (chargingEvent.State.SlowChargeMissingAvailableOutlet) {
    return slowChargerMissingMessage;
  } else if (chargingEvent.State.CannotReachRequiredDistance && chargingEvent.Type !== ChargingEventType.Slow) {
    return unreachableChargingEventsMessage;
  } else if (chargingEvent.AffectedByOtherEvents) {
    return powerNegotiationMessage;
  } else if (chargingEvent.State.EventOccursTooLate) {
    return eventStartTimeOccursToLateMessage;
  } else if (chargingEvent.State.NotEnoughPowerBlocks) {
    return notEnoughPowerBlocksMessage;
  } else if (chargingEvent.State.NotEnoughSatellites) {
    return chargerConnectivetyErrorMessage;
  } else if (chargingEvent.State.NotEnoughTime) {
    return notEnoughTimeToChargeMessage;
  } else if (chargingEvent.State.OverSpeedLimit && checkOverSpeedLimit) {
    return overSpeedLimitMessage;
  }
};

export interface Message {
  header: string;
  body?: ReactNode;
  variant: 'information' | 'error' | 'warning' | 'success';
}

export interface Messages {
  mainErrorMessage?: Message;
  eventStartTimeErrorMessage?: Message;
  chargerErrorMessage?: Message;
  slowChargeTimeErrorMessage?: Message;
  departureTimeErrorMessage?: Message;
}

interface Props {
  currentDatum: Datum<unknown> | null;
  currentChargingEvent?: ChargingEventV2VM;
  departureTime: string;
  eventStartTime: string;
  showCurrentChargingErrorInSetup: boolean;
  slowChargeStartTime: string;
  valuesIsLoaded: boolean;
  validateChargingEventsHandler: ValidateChargingEventsHandler;
}

const useMessages = ({
  currentDatum,
  currentChargingEvent,
  departureTime,
  eventStartTime,
  showCurrentChargingErrorInSetup,
  slowChargeStartTime,
  valuesIsLoaded,
  validateChargingEventsHandler,
}: Props): Messages | undefined =>
  useMemo(() => {
    let mainErrorMessage: Message | undefined = undefined;
    let eventStartTimeErrorMessage: Message | undefined = undefined;
    let chargerErrorMessage: Message | undefined = undefined;
    let slowChargeTimeErrorMessage: Message | undefined = undefined;
    let departureTimeErrorMessage: Message | undefined = undefined;

    if (currentDatum) {
      if (validateChargingEventsHandler.data) {
        const chargingEventSuggestionState = validateChargingEventsHandler.data.ChargingEventSuggestionState;

        if (
          validateChargingEventsHandler.data.ChargingEventIsValid &&
          chargingEventSuggestionState.AffectsOtherVehicles
        ) {
          mainErrorMessage = {
            header: 'Power negotiation',
            body: 'This event will affect other charging events that are taking place at the same time.',
            variant: 'information',
          };
        } else if (!validateChargingEventsHandler.data.ChargingEventIsValid) {
          if (chargingEventSuggestionState.NotEnoughTimeToChargeToRequiredLevel) {
            mainErrorMessage = {
              header: 'Not enough power',
              body: 'To reach the desired SoC, extend the duration or add more power / chargers.',
              variant: 'error',
            };
          }

          if (chargingEventSuggestionState.NoChargersAtDepotPerformanceStep) {
            chargerErrorMessage = chargerConnectivetyErrorMessage;
          }

          if (chargingEventSuggestionState.OverSpeedLimit) {
            eventStartTimeErrorMessage = overSpeedLimitMessage;
          }

          if (!chargerErrorMessage && !eventStartTimeErrorMessage) {
            mainErrorMessage = {
              header: 'Charging event is not valid',
              variant: 'error',
            };
          }
        }
      } else if (currentChargingEvent && showCurrentChargingErrorInSetup) {
        const currentChargingEventMessage = getChargingEventMessage({ chargingEvent: currentChargingEvent });

        if (currentChargingEventMessage) {
          mainErrorMessage = currentChargingEventMessage;
        }
      }

      if (valuesIsLoaded && !eventStartTime) {
        eventStartTimeErrorMessage = {
          header: 'Event start time is required',
          variant: 'error',
        };
      }
    } else {
      if (valuesIsLoaded) {
        if (!departureTime) {
          departureTimeErrorMessage = {
            header: 'Departure time is required',
            variant: 'error',
          };
        }

        if (!slowChargeStartTime) {
          slowChargeTimeErrorMessage = {
            header: 'Slow-charge start time is required',
            variant: 'error',
          };
        }
      }
    }

    if (
      mainErrorMessage ||
      eventStartTimeErrorMessage ||
      chargerErrorMessage ||
      slowChargeTimeErrorMessage ||
      departureTimeErrorMessage
    ) {
      return {
        mainErrorMessage,
        eventStartTimeErrorMessage,
        chargerErrorMessage,
        slowChargeTimeErrorMessage,
        departureTimeErrorMessage,
      };
    }
  }, [
    currentDatum,
    currentChargingEvent,
    departureTime,
    eventStartTime,
    showCurrentChargingErrorInSetup,
    slowChargeStartTime,
    valuesIsLoaded,
    validateChargingEventsHandler,
  ]);

export default useMessages;
