import classes from './style.module.scss';
import classNames from 'classnames';
import { KeyItem } from '.';

interface Props {
  isFull: boolean;
  item: KeyItem;
}

const DepotKeyValue = ({ isFull, item }: Props) => (
  <div
    className={classNames(classes['grid-item'], {
      [classes['is-full']]: isFull,
    })}
    key={item.header}
  >
    <div className={classes['depot-info']}>
      <div>
        <div className={classes['header-and-marker']}>
          <div className={isFull ? 'tds-body-02' : 'tds-detail-02'}>{item.header}</div>
          {item.hasMarker && <div className={classes.marker} />}
        </div>
        <div className="tds-detail-05 tds-text-grey-400">{item.unit}</div>
      </div>
      <div>
        <span className={isFull ? 'tds-headline-01' : 'tds-headline-02'}>{item.value.toLocaleString()}</span>
        {typeof item.total === 'number' && <span className="tds-paragraph-02"> / {item.total.toLocaleString()}</span>}
      </div>
    </div>
  </div>
);

export default DepotKeyValue;
