import classes from './style.module.scss';
import DepotKeyValue from './DepotKeyValue';
import { roundTo } from '@optimization/ssi-common';
import { useMemo } from 'react';

export interface KeyItem {
  header: string;
  unit: string;
  value: number;
  total?: number;
  hasMarker?: boolean;
}

interface Props {
  header?: string;
  powerDemand: number;
  chargingPower: number;
  energyConsumptionPerDayKwh?: number;
  peakConnectedVehicles: number;
  maxUsedPowerBlocks: number;
  totalNumberOfPowerBlocks: number;
  numberOfSatellites: number;
  notEnoughPowerBlocks: boolean;
  notEnoughSatellites: boolean;
  notEnoughPower: boolean;
}

const DepotKeyValues = ({
  header,
  powerDemand,
  chargingPower,
  energyConsumptionPerDayKwh,
  peakConnectedVehicles,
  maxUsedPowerBlocks,
  totalNumberOfPowerBlocks,
  numberOfSatellites,
  notEnoughPowerBlocks,
  notEnoughSatellites,
  notEnoughPower,
}: Props) => {
  const items: KeyItem[] = useMemo(() => {
    let result: KeyItem[] = [
      {
        header: 'Charging power',
        unit: 'kW',
        value: powerDemand,
        total: chargingPower,
        hasMarker: notEnoughPower,
      },
    ];

    if (energyConsumptionPerDayKwh) {
      result.push({
        header: 'Energy consumption',
        unit: 'kWh / day',
        value: roundTo(energyConsumptionPerDayKwh, 0),
      });
    }

    result.push({
      header: 'Power modules',
      unit: 'Units',
      value: maxUsedPowerBlocks,
      total: totalNumberOfPowerBlocks,
      hasMarker: notEnoughPowerBlocks,
    });

    result.push({
      header: 'Connectors',
      unit: 'Count',
      value: peakConnectedVehicles,
      total: numberOfSatellites,
      hasMarker: notEnoughSatellites,
    });

    return result;
  }, [
    powerDemand,
    chargingPower,
    energyConsumptionPerDayKwh,
    maxUsedPowerBlocks,
    totalNumberOfPowerBlocks,
    peakConnectedVehicles,
    numberOfSatellites,
    notEnoughPowerBlocks,
    notEnoughSatellites,
    notEnoughPower,
  ]);

  return (
    <div className={classes['depot-key-values']}>
      {header && <h5 className="tds-headline-06 tds-u-mb1">{header}</h5>}
      <div className={classes.grid}>
        {items.map((item, index) => (
          <DepotKeyValue item={item} key={item.header} isFull={index === 0 && !energyConsumptionPerDayKwh} />
        ))}
      </div>
    </div>
  );
};

export default DepotKeyValues;
