import ActionLayout from 'common/components/ActionLayout';
import classes from './style.module.scss';
import classNames from 'classnames';
import DepotForm from 'features/infrastructure/utils/DepotForm';
import DepotsMap from './utils/DepotsMap';
import DepotTeaser from '../utils/DepotTeaser';
import ErrorBoundary from 'common/components/ErrorBoundary';
import FiltersSelector from 'common/components/Filters/Selector';
import FiltersToggle from 'common/components/Filters/Toggle';
import Grid from 'common/components/Grid';
import HelmetTitle from 'common/components/HelmetTitle';
import LoadMore from 'common/components/LoadMore';
import SolutionPageHeader from 'features/solution/utils/SolutionPageHeader';
import TotalCount from 'common/components/TotalCount';
import useDepotNames from 'common/components/Filters/utils/useDepotNames';
import useFilteredDepots from './utils/useFilteredDepots';
import useFiltersHandler from 'common/components/Filters/utils/useFiltersHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useMultiselectChange from 'common/components/Filters/utils/useMultiselectChange';
import useParseMultipleValues from 'common/components/Filters/utils/useParseMultipleValues';
import useRemoveFilter from 'common/components/Filters/utils/useRemoveFilter';
import useSelectedFilters from './utils/useSelectedFilters';
import useSolutionHasDepots from './utils/useSolutionHasDepots';
import { BackLink } from '@optimization/sa-common';
import { DEPOTS_MAP_ENABLED, MULTIPLE_VALUES_SEPARATOR } from 'app/config';
import { ListDepotTransformed } from '@optimization/sa-common';
import { Option } from 'common/components/Filters/utils/types';
import { SortFunctions, useSortItems } from '@optimization/ssi-common';
import { sortModifiedAsc, sortModifiedDesc } from '@optimization/sa-common';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Button,
  Dropdown,
  invariant,
  Loading,
  useNamesToDropdownOptions,
  useSplicedItems,
} from '@optimization/ssi-common';
import {
  useGetDepotsQuery,
  useGetSolutionQuery,
  useGetVehiclesQuery,
  useGetFactBasicDataQuery,
} from 'app/services/solution';

const CLEAR_ALL_AT_ONCE_KEYS = ['depotNames'];
const SORT_DIRECTION_DEFAULT = 'desc';

const sortOptions: Option[] = [{ type: 'date', name: 'Last modified' }];

const sortFunctions: SortFunctions = {
  date: {
    asc: sortModifiedAsc,
    desc: sortModifiedDesc,
  },
};

const DepotManager = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const [searchParams] = useSearchParams();

  const solutionQuery = useGetSolutionQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);
  const vehiclesQuery = useGetVehiclesQuery(solutionId);
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const solutionHasDepots = useSolutionHasDepots({ solutionId });
  const [showDepotForm, setShowDepotForm] = useState(false);
  const actionLayoutRef = useRef<HTMLDivElement | null>(null);

  const filterParams = useMemo(
    () => ({
      depotNames: searchParams.get('depotNames') || '',
      sort: searchParams.get('sort') || 'date',
      sortDirection: searchParams.get('sortDirection') || SORT_DIRECTION_DEFAULT,
    }),
    [searchParams],
  );

  const sortType = filterParams.sort;
  const sortDirection = filterParams.sortDirection;
  const [depotsCount, setDepotsCount] = useState<number | undefined>(undefined);

  const filtersHandler = useFiltersHandler({
    filterParams,
    sortDirectionDefault: SORT_DIRECTION_DEFAULT,
  });

  const selectedDepotNames = useParseMultipleValues(filterParams.depotNames);

  const selectedFilters = useSelectedFilters({ selectedDepotNames });

  const onRemoveFilter = useRemoveFilter({
    filterParams,
    clearAllAtOnceKeys: CLEAR_ALL_AT_ONCE_KEYS,
  });

  const depotNames = useDepotNames({ depots: depotsQuery.data });

  const depotNamesOptions = useNamesToDropdownOptions({
    names: depotNames,
  });

  const onChangeDepotNames = useMultiselectChange({
    filterParams,
    filterKey: 'depotNames',
  });

  const filteredDepots = useFilteredDepots({
    depots: depotsQuery.data,
    selectedDepotNames,
  });

  const sortedDepots = useSortItems<ListDepotTransformed>({
    items: filteredDepots,
    sortFunctions,
    sortType,
    sortDirection,
  });

  const splicedDepots = useSplicedItems<ListDepotTransformed>({
    items: sortedDepots,
    count: depotsCount,
  });

  const onShowDepotForm = useCallback(() => {
    setShowDepotForm(true);
  }, []);

  const onCancelDepotForm = useCallback(() => {
    setShowDepotForm(false);
  }, []);

  useMakeElementsSticky({
    actionLayoutRef,
  });

  const isLoading =
    solutionQuery.isLoading || depotsQuery.isFetching || vehiclesQuery.isLoading || factBasicDataQuery.isLoading;

  const isError = solutionQuery.isError || depotsQuery.isError || vehiclesQuery.isError || factBasicDataQuery.isError;

  const isSuccess =
    solutionQuery.isSuccess && depotsQuery.isSuccess && vehiclesQuery.isSuccess && factBasicDataQuery.isSuccess;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isLoading,
    isLoadingDepots: depotsQuery.isLoading,
    isLoadingCandidates: vehiclesQuery.isLoading || factBasicDataQuery.isLoading,
  });

  if (showDepotForm) {
    return <DepotForm solutionId={solutionId} onCancelDepotForm={onCancelDepotForm} />;
  }

  return (
    <>
      <div className="tds-container">
        <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
        <HelmetTitle solutionId={solutionId} items={['Depots']} />
        {solutionQuery.data && (
          <>
            <BackLink url="/" className="mb-spacing-64">
              All solutions
            </BackLink>
            <SolutionPageHeader
              solutionName={solutionQuery.data.Name}
              intro="Depot management"
              description={[
                "Define the customer's upcoming depot infrastructure and charging events for vehicle candidates. Add depots, map vehicle candidates and specify daily charging events. Scale the charging infrastructure in yearly performance steps.",
              ]}
              cta={
                isSuccess &&
                !DEPOTS_MAP_ENABLED && (
                  <div className="inline-block">
                    <Button
                      text="Add depot"
                      variant={splicedDepots?.length ? 'secondary' : 'primary'}
                      size="md"
                      dataTestid="button-create-new-depot"
                      onClick={onShowDepotForm}
                    />
                  </div>
                )
              }
            >
              {solutionQuery.data.transformed.solutionNamePresentation}
            </SolutionPageHeader>
          </>
        )}
        {DEPOTS_MAP_ENABLED && (
          <ErrorBoundary>
            <div className="mt-spacing-50">
              <h4 className="tds-headline-04">Create depots by locating them in the map</h4>
              <p className="mt-spacing-2">Interact with the map to geospatially locate the customer’s depots</p>
            </div>
            <DepotsMap solutionId={solutionId} depots={depotsQuery.data} onShowDepotForm={onShowDepotForm} />
            {/* Only for making e2e tests work. Because how do we make Playwright clicking the map to create a depot? */}
            <button
              type="button"
              data-testid="button-create-new-depot"
              className={classNames('reset-button-styles', classes['create-new-depot'])}
              onClick={onShowDepotForm}
            />
          </ErrorBoundary>
        )}
        {solutionHasDepots && (
          <>
            <ActionLayout
              actionLayoutRef={actionLayoutRef}
              className="mt-spacing-72"
              left={<TotalCount count={filteredDepots?.length} entityName="DEPOT" />}
              right={
                <FiltersToggle
                  sortType={sortType}
                  sortDirection={sortDirection === 'asc' ? 'asc' : 'desc'}
                  sortOptions={sortOptions}
                  activeAction={filtersHandler.activeFiltersAction}
                  toggleFilters={filtersHandler.toggleFilters}
                  toggleSortBy={filtersHandler.toggleSortBy}
                  toggleSortDirection={filtersHandler.toggleSortDirection}
                  selectedFilters={selectedFilters}
                  onRemoveFilter={onRemoveFilter}
                />
              }
              below={
                <FiltersSelector
                  sortType={sortType}
                  sortOptions={sortOptions}
                  onChangeSortType={filtersHandler.onChangeSortType}
                  activeAction={filtersHandler.activeFiltersAction}
                  hideFilters={filtersHandler.hideFilters}
                >
                  <Dropdown
                    label="Filter by depot name"
                    labelPosition="outside"
                    placeholder="Select depot name"
                    className="w-250"
                    multiselect
                    filter
                    options={depotNamesOptions}
                    value={selectedDepotNames.join(MULTIPLE_VALUES_SEPARATOR)}
                    onChange={onChangeDepotNames}
                  />
                </FiltersSelector>
              }
            />
          </>
        )}
        {splicedDepots && vehiclesQuery.data && (
          <>
            <Grid>
              {splicedDepots.map((depot) => (
                <DepotTeaser key={depot.DepotId} depot={depot} solutionId={solutionId} />
              ))}
            </Grid>
            <LoadMore
              contentName="Depots"
              itemsCount={splicedDepots.length}
              totalCount={filteredDepots?.length}
              setItemsCount={setDepotsCount}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DepotManager;
