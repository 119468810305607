import chartData from './chartData';
import classes from './style.module.scss';
import { Button, Checkbox } from '@optimization/ssi-common';
import { useCallback, useMemo } from 'react';
import { useFinancialEvaluation } from 'app/context/FinancialEvaluationContext';
import { FinancialEvaluationVisual, VehicleEnhanced } from '@optimization/sa-common';

interface Props {
  vehicle: VehicleEnhanced;
}

const FinancialEvaluation = ({ vehicle }: Props) => {
  const financialEvaluation = useFinancialEvaluation();

  const evaluationExists = useMemo(
    () => Boolean(financialEvaluation && financialEvaluation.evaluations?.[vehicle.Id]),
    [vehicle, financialEvaluation],
  );

  const evaluationIsDone = useMemo(
    () => Boolean(financialEvaluation && financialEvaluation.evaluations?.[vehicle.Id]?.isDone),
    [financialEvaluation, vehicle],
  );

  const enableInPresentation = useMemo(
    () => Boolean(financialEvaluation && financialEvaluation.evaluations?.[vehicle.Id]?.enableInPresentation),
    [financialEvaluation, vehicle],
  );

  const toggleEnableTcoInPresentation = useCallback(() => {
    if (financialEvaluation) {
      financialEvaluation.setEnableEvaluationInPresentation({
        vehicleId: vehicle.Id,
        enableInPresentation: !enableInPresentation,
      });
    }
  }, [financialEvaluation, enableInPresentation, vehicle]);

  return (
    <div className={classes['financial-evaluation']}>
      <FinancialEvaluationVisual
        fromHeader={evaluationExists ? 'Diesel' : '...'}
        data={evaluationIsDone ? chartData : undefined}
      />
      <div className={classes.actions}>
        <Button
          variant={evaluationIsDone ? 'secondary' : 'primary'}
          size="sm"
          text={evaluationIsDone ? 'Edit evaluation' : 'Setup evaluation'}
          dataTestid="button-setup-financial-evaluation"
          onClick={financialEvaluation ? () => financialEvaluation.startSetupEvaluation(vehicle) : undefined}
        />
        <Checkbox
          onChange={toggleEnableTcoInPresentation}
          label="Enable TCO in presentation"
          value="true"
          checked={enableInPresentation}
          disabled={evaluationExists === false}
        />
      </div>
    </div>
  );
};

export default FinancialEvaluation;
