import chargingSpecificationCheck from '../defaultOptions/chargingSpecificationCheck';
import chassisAdaptationCheck from '../defaultOptions/chassisAdaptationCheck';
import sortByPortfoliosAsc from './sortByPortfoliosAsc';
import { FactBasicBus } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  basicBusses: FactBasicBus[];
  selections: SelectionExecutions;
}

const useSelectedBasicBusEarliest = ({ basicBusses, selections }: Props) =>
  useMemo(() => {
    const busses = basicBusses.filter(
      (basicBus) =>
        chassisAdaptationCheck({
          basicVehicle: basicBus,
          selectedBattery: selections.busBattery,
          selectedSocWindow: selections.busSocWindow,
          selectedElectricMotor: selections.busElectricMotor,
        }) &&
        basicBus.WheelConfiguration === selections.busWheelConfiguration.toLocaleLowerCase() &&
        basicBus.BodyLengthsM.includes(selections.busBodyLength) &&
        basicBus.ChassisHeights.includes(selections.busChassisHeight) &&
        basicBus.BusFloorHeights.includes(selections.busFloorHeight) &&
        chargingSpecificationCheck({
          chargingSpecifications: basicBus.ChargingSpecifications,
          selectedChargingType: selections.busChargingType,
        }),
    );

    if (busses.length) {
      busses.sort(sortByPortfoliosAsc);
      return busses[0];
    }

    return null;
  }, [basicBusses, selections]);

export default useSelectedBasicBusEarliest;
