import useInvalidateSolutionCache from 'common/hooks/useInvalidateSolutionCache';
import { getEngineGearbox, getVehicleSoc } from 'common/utils/helper';
import { parseChargingTypeValue } from 'features/candidate/utils/vehicleOptionsHandler/defaultOptions/useBusChargingTypeOptions';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { solutionApi } from 'app/services/solution';
import { useCallback, useMemo, useState } from 'react';
import { useDeleteChargingEventsMutation, useUpdateVehicleMutation } from 'app/services/solution';
import { usePlural, useToast } from '@optimization/ssi-common';
import {
  ExecutedVehicleProductV2VM,
  FactBasicBus,
  FactBasicTruck,
  ListVehicleTransformed,
} from '@optimization/sa-common';

export type OnConfigureVehicleProduct = ({
  selections,
  selectedBasicTruck,
  selectedBasicBus,
}: {
  selections: SelectionExecutions;
  selectedBasicTruck: FactBasicTruck | null;
  selectedBasicBus: FactBasicBus | null;
}) => Promise<void>;

interface Props {
  solutionId: string;
  vehicles?: ListVehicleTransformed[];
  selectedCandidates: string[];
  closePanel: () => void;
  setSelectedCandidates: React.Dispatch<React.SetStateAction<string[]>>;
  resetMode: () => void;
}

interface Result {
  isLoading: boolean;
  isError: boolean;
  onConfigureVehicleProduct: OnConfigureVehicleProduct;
}

const useConfigureVehicleProductHandler = ({
  solutionId,
  vehicles,
  selectedCandidates,
  closePanel,
  setSelectedCandidates,
  resetMode,
}: Props): Result => {
  const showToast = useToast();
  const [getVehicle, vehicleQueryState] = solutionApi.endpoints.getVehicle.useLazyQuery();
  const setInvalidateSolutionTags = useInvalidateSolutionCache();
  const [updateVehicle] = useUpdateVehicleMutation();
  const [deleteChargingEvents] = useDeleteChargingEventsMutation();

  const [configureVehicleProductState, setConfigureVehicleProductState] = useState({
    isLoading: false,
    isError: false,
  });

  const candidatesPlural = usePlural({
    count: selectedCandidates.length,
  });

  const onConfigureVehicleProduct = useCallback(
    async ({
      selections,
      selectedBasicTruck,
      selectedBasicBus,
    }: {
      selections: SelectionExecutions;
      selectedBasicTruck: FactBasicTruck | null;
      selectedBasicBus: FactBasicBus | null;
    }) => {
      setConfigureVehicleProductState({ isLoading: true, isError: false });

      const selectedVehicles = vehicles?.filter((vehicle) => selectedCandidates.includes(vehicle.Id.toString()));

      const selectedBasicVehicle = selections.chassisType === 'Truck' ? selectedBasicTruck : selectedBasicBus;

      if (selectedVehicles && selectedBasicVehicle) {
        try {
          await Promise.all(
            selectedVehicles.map(async (selectedVehicle) => {
              const getVehicleQuery = await getVehicle({
                solutionId,
                vehicleId: selectedVehicle.Id,
              });

              if (getVehicleQuery.isError) {
                throw new Error();
              }

              const vehicle = getVehicleQuery.data;

              if (vehicle) {
                const deleteChargingEventsResponse = await deleteChargingEvents({
                  solutionId,
                  vehicleId: vehicle.Id,
                  noInvalidates: true,
                });

                if ('error' in deleteChargingEventsResponse) {
                  throw new Error();
                }

                let executedVehicleProduct: ExecutedVehicleProductV2VM | undefined;

                if (selections.chassisType === 'Truck' && selectedBasicTruck) {
                  executedVehicleProduct = {
                    ...vehicle.ExecutedVehicleProduct,
                    Soc: getVehicleSoc(selectedBasicTruck, selections.truckSocWindow),
                    ChargingSpecification: selectedBasicTruck.ChargingSpecifications[0],
                    EngineGearbox: getEngineGearbox(selectedBasicTruck, selections.truckElectricMotor),
                    ChassisAdaptation: selections.truckChassisAdaptation,
                    WheelConfiguration: selections.truckWheelConfiguration,
                    AxleDistanceMm: Number(selections.truckAxleDistance),
                    ChassisHeight: selections.truckChassisHeight,
                    Cab: selections.truckCabType,
                  };
                } else if (selections.chassisType === 'Bus' && selectedBasicBus) {
                  const chargingTypeParts = parseChargingTypeValue(selections.busChargingType);

                  if (chargingTypeParts.length !== 2) {
                    throw new Error();
                  }

                  const [chargingType, chargingPowerKW] = chargingTypeParts;

                  executedVehicleProduct = {
                    ...vehicle.ExecutedVehicleProduct,
                    Soc: getVehicleSoc(selectedBasicBus, selections.busSocWindow),
                    ChargingSpecification: {
                      ChargingType: chargingType,
                      ChargingPowerKW: Number(chargingPowerKW),
                    },
                    EngineGearbox: getEngineGearbox(selectedBasicBus, selections.busElectricMotor),
                    WheelConfiguration: selections.busWheelConfiguration,
                    BodyLengthsM: selections.busBodyLength,
                    ChassisHeight: selections.busChassisHeight,
                    BusFloorHeight: selections.busFloorHeight,
                  };
                }

                if (!executedVehicleProduct) {
                  throw new Error();
                }

                const response = await updateVehicle({
                  solutionId,
                  vehicle: {
                    ...vehicle,
                    ExecutedVehicleProduct: {
                      ...executedVehicleProduct,
                      CompositeId: selectedBasicVehicle.CompositeId,
                      Status: selectedBasicVehicle.Status,
                      LaunchPeriod: selectedBasicVehicle.Period,
                      Discontinued: selectedBasicVehicle.Discontinued || '',
                      InstalledEnergyKWh: selectedBasicVehicle.InstalledEnergyKWh,
                    },
                  },
                  noInvalidates: true,
                });

                if ('error' in response) {
                  throw new Error();
                }

                return response;
              }
            }),
          );

          setInvalidateSolutionTags([{ type: 'Solutions', id: solutionId }, { type: 'Vehicles' }, { type: 'Depots' }]);

          closePanel();

          showToast({
            header: `${selectedCandidates.length} vehicle product${candidatesPlural} configured successfully`,
            dataTestid: 'toast-vehicle-products-changed',
          });

          setConfigureVehicleProductState({ isLoading: false, isError: false });
          setSelectedCandidates([]);
          resetMode();
        } catch (e) {
          console.error(e);
          setConfigureVehicleProductState({ isLoading: false, isError: true });
        }
      }
    },
    [
      solutionId,
      vehicles,
      selectedCandidates,
      candidatesPlural,
      closePanel,
      deleteChargingEvents,
      getVehicle,
      resetMode,
      setInvalidateSolutionTags,
      setSelectedCandidates,
      showToast,
      updateVehicle,
    ],
  );

  const isLoading = configureVehicleProductState.isLoading || vehicleQueryState.isLoading;

  const isError = configureVehicleProductState.isError || vehicleQueryState.isError;

  return useMemo(
    () => ({ onConfigureVehicleProduct, isLoading, isError }),
    [onConfigureVehicleProduct, isLoading, isError],
  );
};

export default useConfigureVehicleProductHandler;
