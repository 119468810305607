import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { Dropdown, TextField } from '@optimization/ssi-common';
import { FormValues, SetFormValue } from '../..';

const financeOptions = [
  { value: 'leasing', name: 'Leasing' },
  { value: 'cash', name: 'Cash' },
];

interface Props {
  className?: string;
  disabled?: boolean;
  fromCostsHeader: string;
  formValues: FormValues;
  setFormValue: SetFormValue;
}

const VehicleCostsFields = ({ className, disabled, fromCostsHeader, formValues, setFormValue }: Props) => {
  const onChangeBevFinanceType = useOnChangeFieldValue({
    attribute: 'toFinanceType',
    setFormValue,
  });

  const onChangeDieselFinanceType = useOnChangeFieldValue({
    attribute: 'fromFinanceType',
    setFormValue,
  });

  const onChangeBevVehiclePrice = useOnChangeFieldValue({
    attribute: 'toVehiclePrice',
    setFormValue,
  });

  const onChangeDieselVehiclePrice = useOnChangeFieldValue({
    attribute: 'fromVehiclePrice',
    setFormValue,
  });

  const onChangeBevInterestRate = useOnChangeFieldValue({
    attribute: 'toInterestRate',
    setFormValue,
  });

  const onChangeDieselInterestRate = useOnChangeFieldValue({
    attribute: 'fromInterestRate',
    setFormValue,
  });

  const onChangeBevResidual = useOnChangeFieldValue({
    attribute: 'toResidual',
    setFormValue,
  });

  const onChangeDieselResidual = useOnChangeFieldValue({
    attribute: 'fromResidual',
    setFormValue,
  });

  const onChangeBevGovernmentSubsidies = useOnChangeFieldValue({
    attribute: 'toGovernmentSubsidies',
    setFormValue,
  });

  const onChangeDieselGovernmentSubsidies = useOnChangeFieldValue({
    attribute: 'fromGovernmentSubsidies',
    setFormValue,
  });

  return (
    <div className={classNames(classes['vehicle-costs-fields'], className)}>
      <div className={classNames(classes['header-1'], 'tds-detail-04')}>BEV</div>
      <div className={classNames(classes['header-2'], 'tds-detail-04')}>{fromCostsHeader}</div>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Type of financing</div>
      <Dropdown
        className={classes['field-1-1']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={financeOptions}
        value={formValues.toFinanceType}
        onChange={onChangeBevFinanceType}
      />
      <Dropdown
        className={classes['field-1-2']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={financeOptions}
        value={formValues.fromFinanceType}
        onChange={onChangeDieselFinanceType}
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Vehicle price</div>
      <TextField
        className={classes['field-2-1']}
        type="number"
        value={formValues.toVehiclePrice}
        size="lg"
        suffixText="EUR"
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevVehiclePrice}
      />
      <TextField
        className={classes['field-2-2']}
        type="number"
        value={formValues.fromVehiclePrice}
        size="lg"
        suffixText="EUR"
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselVehiclePrice}
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Interest rate</div>
      <TextField
        className={classes['field-3-1']}
        type="number"
        size="lg"
        suffixText={formValues.toFinanceType === 'cash' ? 'N/A' : '%'}
        disabled={disabled || formValues.toFinanceType === 'cash'}
        min={0}
        noMinWidth
        onChange={onChangeBevInterestRate}
        value={formValues.toFinanceType === 'cash' ? '' : formValues.toInterestRate}
      />
      <TextField
        className={classes['field-3-2']}
        type="number"
        size="lg"
        suffixText={formValues.fromFinanceType === 'cash' ? 'N/A' : '%'}
        disabled={disabled || formValues.fromFinanceType === 'cash'}
        min={0}
        noMinWidth
        onChange={onChangeDieselInterestRate}
        value={formValues.fromFinanceType === 'cash' ? '' : formValues.fromInterestRate}
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Residual value</div>
      <TextField
        className={classes['field-4-1']}
        type="number"
        value={formValues.toResidual}
        size="lg"
        suffixText="EUR"
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevResidual}
      />
      <TextField
        className={classes['field-4-2']}
        type="number"
        value={formValues.fromResidual}
        size="lg"
        suffixText="EUR"
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselResidual}
      />
      <div className={classNames('tds-detail-04', classes['label-5'])}>Government subsidies</div>
      <TextField
        className={classes['field-5-1']}
        type="number"
        value={formValues.toGovernmentSubsidies}
        size="lg"
        suffixText="EUR"
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeBevGovernmentSubsidies}
      />
      <TextField
        className={classes['field-5-2']}
        type="number"
        value={formValues.fromGovernmentSubsidies}
        size="lg"
        suffixText="EUR"
        disabled={disabled}
        min={0}
        noMinWidth
        onChange={onChangeDieselGovernmentSubsidies}
      />
    </div>
  );
};

export default VehicleCostsFields;
