import { AVG_SPEED_DEFAULT, CRUISE_SPEED_DEFAULT, VehicleTransformed } from '@optimization/sa-common';
import { FormState } from '../Main';
import { SUMMER_MAX_TEMP_DEFEAULT, WINTER_MIN_TEMP_DEFAULT } from 'app/config';
import { useEffect } from 'react';

interface Props {
  vehicle: VehicleTransformed;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const useReadVehicleIntoFormState = ({ vehicle, setFormState }: Props) => {
  useEffect(() => {
    if (vehicle.ExecutedVehicleProduct) {
      setFormState((prev) => ({
        ...prev,
        temperatureWinterC:
          vehicle.ExecutedVehicleProduct.TemperatureWinterC?.toString() || WINTER_MIN_TEMP_DEFAULT.toString(),
        temperatureSummerC:
          vehicle.ExecutedVehicleProduct.TemperatureSummerC?.toString() || SUMMER_MAX_TEMP_DEFEAULT.toString(),
        avgGtw: vehicle.ExecutedVehicleProduct.AvgGtwKg?.toString() || '',
        avgSpeed: vehicle.ExecutedVehicleProduct.AvgSpeedKmh?.toString() || AVG_SPEED_DEFAULT.toString(),
        cruisingSpeed: vehicle.ExecutedVehicleProduct.CruiseSpeedKmh?.toString() || CRUISE_SPEED_DEFAULT.toString(),
        dailyOdometerMedian: vehicle.ExecutedVehicleProduct.DailyOdometerMedianKm.toString() || '',
        dailyOdometerLongest: vehicle.ExecutedVehicleProduct.DailyOdometerLongestKm?.toString() || '',
        topography: vehicle.ExecutedVehicleProduct.Topography || '',
        traffic: vehicle.ExecutedVehicleProduct.Traffic || '',
      }));
    }
  }, [vehicle, setFormState]);
};

export default useReadVehicleIntoFormState;
