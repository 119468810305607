import ChargingEvents from './ChargingEvents';
import classNames from 'classnames';
import usePixelsPerMinute from './utils/usePixelsPerMinute';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { useRef } from 'react';
import './style.scss';

export const HOURS = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22];

interface Props {
  className?: string;
  depotId: number;
  depotYear: string;
  solutionId: string;
  vehicles: ListVehicleTransformed[];
}

const Occupancy = ({ className, depotId, depotYear, solutionId, vehicles }: Props) => {
  const columnRef = useRef<HTMLTableCellElement>(null);
  const pixelsPerMinute = usePixelsPerMinute(columnRef);

  return (
    <div data-testid="occupancy" className={classNames('occupancy', className)}>
      {vehicles.length > 0 ? (
        <>
          <div className="flex tds-u-mt3 items-center">
            <h2 className="tds-detail-04">Vehicles</h2>
            <ul className="occupancy-legend tds-u-ml-auto">
              <li>
                <span className="occupancy-legend-square" />
                Charging event
              </li>
            </ul>
          </div>
          <table className="tds-u-mt3">
            <thead>
              <tr>
                {HOURS.map((hour, index) => (
                  <th key={hour}>
                    <div className="occupancy-hours tds-detail-05">
                      <div className="occupancy-hours-hour" style={{ left: hour < 10 ? '-5px' : '-8px' }}>
                        {hour}
                      </div>
                      {index === HOURS.length - 1 && (
                        <div className="occupancy-hours-hour" style={{ right: '-7px' }}>
                          24
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {vehicles.map((listVehicle, rowIndex) => (
                <tr key={listVehicle.Id}>
                  {HOURS.map((hour, tdIndex) => (
                    <td key={hour} ref={rowIndex === 0 && tdIndex === 0 ? columnRef : null}>
                      {pixelsPerMinute > 0 && (
                        <ChargingEvents
                          depotId={depotId}
                          depotYear={depotYear}
                          listVehicle={listVehicle}
                          pixelsPerMinute={pixelsPerMinute}
                          solutionId={solutionId}
                          tdIndex={tdIndex}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div>Found no vehicles in depot</div>
      )}
    </div>
  );
};

export default Occupancy;
