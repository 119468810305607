import { calculatePtoEc } from 'common/utils/helper';
import { NET_EC_PARAM_DEFAULT, VehicleEnhanced } from '@optimization/sa-common';
import { roundTo } from '@optimization/ssi-common';
import { useMemo } from 'react';

const regenTraffic = {
  FluidTraffic: 0.15,
  LightTraffic: 0.15,
  ResidentialTraffic: 0.22,
  DenseTraffic: 0.22,
  StartStop: 0.22,
  VeryFrequentStartStop: 0.3,
};

const useBatteryLife = (vehicle: VehicleEnhanced) =>
  useMemo(() => {
    const vehicleTraffic = vehicle.ExecutedVehicleProduct?.Traffic;
    const maxCycledEnergy = vehicle.ExecutedVehicleProduct.Soc?.MaxCycledEnergyMWh;

    if (!vehicleTraffic || !maxCycledEnergy) {
      return 0;
    }

    const engineGearboxValue = vehicle.ExecutedVehicleProduct.EngineGearbox?.Name.includes('C14') ? 0.95 : 1;

    const summerEc = vehicle.EcCalculations?.[`Summer-${NET_EC_PARAM_DEFAULT}`]?.TotalKWhPerKm || 1;

    const winterEc = vehicle.EcCalculations?.[`Winter-${NET_EC_PARAM_DEFAULT}`]?.TotalKWhPerKm || 1;

    const netEcAverage = roundTo((summerEc + winterEc) / 2, 2);
    const totEcAverage = roundTo(
      netEcAverage + calculatePtoEc(vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs),
      2,
    );

    const installedEnergy = vehicle.enhanced.basicTruck?.InstalledEnergyKWh || 0;

    //@ts-ignore
    const trafficValue = regenTraffic[vehicleTraffic];

    const years =
      maxCycledEnergy /
      ((totEcAverage * 2 * engineGearboxValue + netEcAverage * engineGearboxValue * trafficValue * 2) *
        vehicle.ExecutedVehicleProduct.DailyOdometerMedianKm *
        250 *
        0.001);

    return Math.min(years, installedEnergy < 416 ? 8 : 12);
  }, [vehicle]);

export default useBatteryLife;
