import useSuggestedAxleDistance from './useSuggestedAxleDistance';
import useSuggestedBattery from './useSuggestedBattery';
import useSuggestedBodyLength from './useSuggestedBodyLength';
import useSuggestedCabType from './useSuggestedCabType';
import useSuggestedCharginType from './useSuggestedCharginType';
import useSuggestedChassisAdaptation from './useSuggestedChassisAdaptation';
import useSuggestedChassisHeight from './useSuggestedChassisHeight';
import useSuggestedEngineGearBox from './useSuggestedEngineGearBox';
import useSuggestedFloorHeight from './useSuggestedFloorHeight';
import useSuggestedPortfolio from './useSuggestedPortfolio';
import useSuggestedSoc from './useSuggestedSoc';
import useSuggestedWheelConfiguration from './useSuggestedWheelConfiguration';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useEffect } from 'react';

interface Props {
  chassisType: string;
  vehicles?: ListVehicleTransformed[];
  truckPortfolioList: string[];
  truckBatteryList: number[];
  truckSocList: number[];
  truckEngineGearBoxList: string[];
  truckChassisAdaptationList: string[];
  truckWheelConfigurationList: string[];
  truckAxleDistanceList: string[];
  truckChassisHeightList: string[];
  truckCabTypeList: string[];
  busPortfolioList: string[];
  busBatteryList: number[];
  busSocList: number[];
  busChargingTypeList: string[];
  busEngineGearBoxList: string[];
  busWheelConfigurationList: string[];
  busBodyLengthList: string[];
  busChassisHeightList: string[];
  busFloorHeightList: string[];
  preSelectOptionsInitially?: boolean;
  setSelections: React.Dispatch<React.SetStateAction<SelectionExecutions>>;
}

const useLoadSelectionBySuggestion = ({
  chassisType,
  vehicles = [],
  truckPortfolioList,
  truckBatteryList,
  truckSocList,
  truckEngineGearBoxList,
  truckChassisAdaptationList,
  truckWheelConfigurationList,
  truckAxleDistanceList,
  truckChassisHeightList,
  truckCabTypeList,
  busPortfolioList,
  busBatteryList,
  busSocList,
  busChargingTypeList,
  busEngineGearBoxList,
  busWheelConfigurationList,
  busBodyLengthList,
  busChassisHeightList,
  busFloorHeightList,
  preSelectOptionsInitially,
  setSelections,
}: Props) => {
  // Truck suggestions
  const suggestedTruckPortfolio = useSuggestedPortfolio({
    vehicles,
    portfolioList: truckPortfolioList,
  });

  const suggestedTruckBattery = useSuggestedBattery({ vehicles, batteryList: truckBatteryList });

  const suggestedTruckSoc = useSuggestedSoc({ vehicles, socList: truckSocList });

  const suggestedTruckEngineGearBox = useSuggestedEngineGearBox({
    vehicles,
    engineGearBoxList: truckEngineGearBoxList,
  });

  const suggestedTruckChassisAdaptation = useSuggestedChassisAdaptation({
    vehicles,
    truckChassisAdaptationList,
  });

  const suggestedTruckWheelConfiguration = useSuggestedWheelConfiguration({
    vehicles,
    wheelConfigurationList: truckWheelConfigurationList,
  });

  const suggestedTruckAxleDistance = useSuggestedAxleDistance({
    vehicles,
    truckAxleDistanceList,
  });

  const suggestedTruckChassisHeight = useSuggestedChassisHeight({
    vehicles,
    chassisHeightList: truckChassisHeightList,
  });

  const suggestedTruckCabType = useSuggestedCabType({
    vehicles,
    truckCabTypeList,
  });

  // Bus suggestions
  const suggestedBusPortfolio = useSuggestedPortfolio({
    vehicles,
    portfolioList: busPortfolioList,
  });

  const suggestedBusBattery = useSuggestedBattery({ vehicles, batteryList: busBatteryList });

  const suggestedBusSoc = useSuggestedSoc({ vehicles, socList: busSocList });

  const suggestedBusChargingType = useSuggestedCharginType({
    vehicles,
    chargingTypeList: busChargingTypeList,
  });

  const suggestedBusEngineGearBox = useSuggestedEngineGearBox({
    vehicles,
    engineGearBoxList: busEngineGearBoxList,
  });

  const suggestedBusWheelConfiguration = useSuggestedWheelConfiguration({
    vehicles,
    wheelConfigurationList: busWheelConfigurationList,
  });

  const suggestedBusBodyLength = useSuggestedBodyLength({
    vehicles,
    bodyLengthList: busBodyLengthList,
  });

  const suggestedBusChassisHeight = useSuggestedChassisHeight({
    vehicles,
    chassisHeightList: busChassisHeightList,
  });

  const suggestedBusFloorHeight = useSuggestedFloorHeight({
    vehicles,
    floorHeightList: busFloorHeightList,
  });

  useEffect(() => {
    if (chassisType === 'Truck' && suggestedTruckPortfolio && preSelectOptionsInitially) {
      setSelections((prev) => ({
        ...prev,
        isChangedByUser: true,
        truckPortfolio: !prev.truckPortfolio ? suggestedTruckPortfolio : prev.truckPortfolio,
      }));
    }
  }, [chassisType, suggestedTruckPortfolio, preSelectOptionsInitially, setSelections]);

  useEffect(() => {
    if (chassisType === 'Bus' && suggestedBusPortfolio && preSelectOptionsInitially) {
      setSelections((prev) => ({
        ...prev,
        isChangedByUser: true,
        busPortfolio: !prev.busPortfolio ? suggestedBusPortfolio : prev.busPortfolio,
      }));
    }
  }, [chassisType, suggestedBusPortfolio, preSelectOptionsInitially, setSelections]);

  useEffect(() => {
    setSelections((prev) =>
      prev.isChangedByUser
        ? {
            ...prev,
            truckPortfolio:
              !prev.truckPortfolio && suggestedTruckPortfolio ? suggestedTruckPortfolio : prev.truckPortfolio,
            truckBattery: !prev.truckBattery && suggestedTruckBattery ? suggestedTruckBattery : prev.truckBattery,
            truckSocWindow: !prev.truckSocWindow && suggestedTruckSoc ? suggestedTruckSoc : prev.truckSocWindow,
            truckElectricMotor:
              !prev.truckElectricMotor && suggestedTruckEngineGearBox
                ? suggestedTruckEngineGearBox
                : prev.truckElectricMotor,
            truckChassisAdaptation:
              !prev.truckChassisAdaptation && suggestedTruckChassisAdaptation
                ? suggestedTruckChassisAdaptation
                : prev.truckChassisAdaptation,
            truckWheelConfiguration:
              !prev.truckWheelConfiguration && suggestedTruckWheelConfiguration
                ? suggestedTruckWheelConfiguration
                : prev.truckWheelConfiguration,
            truckAxleDistance:
              !prev.truckAxleDistance && suggestedTruckAxleDistance
                ? suggestedTruckAxleDistance
                : prev.truckAxleDistance,
            truckChassisHeight:
              !prev.truckChassisHeight && suggestedTruckChassisHeight
                ? suggestedTruckChassisHeight
                : prev.truckChassisHeight,
            truckCabType: suggestedTruckCabType ? suggestedTruckCabType : prev.truckCabType,
            busPortfolio: !prev.busPortfolio && suggestedBusPortfolio ? suggestedBusPortfolio : prev.busPortfolio,
            busBattery: !prev.busBattery && suggestedBusBattery ? suggestedBusBattery : prev.busBattery,
            busSocWindow: !prev.busSocWindow && suggestedBusSoc ? suggestedBusSoc : prev.busSocWindow,
            busChargingType:
              !prev.busChargingType && suggestedBusChargingType ? suggestedBusChargingType : prev.busChargingType,
            busElectricMotor:
              !prev.busElectricMotor && suggestedBusEngineGearBox ? suggestedBusEngineGearBox : prev.busElectricMotor,
            busWheelConfiguration:
              !prev.busWheelConfiguration && suggestedBusWheelConfiguration
                ? suggestedBusWheelConfiguration
                : prev.busWheelConfiguration,
            busBodyLength: !prev.busBodyLength && suggestedBusBodyLength ? suggestedBusBodyLength : prev.busBodyLength,
            busChassisHeight:
              !prev.busChassisHeight && suggestedBusChassisHeight ? suggestedBusChassisHeight : prev.busChassisHeight,
            busFloorHeight:
              !prev.busFloorHeight && suggestedBusFloorHeight ? suggestedBusFloorHeight : prev.busFloorHeight,
          }
        : prev,
    );
  }, [
    suggestedTruckPortfolio,
    suggestedTruckBattery,
    suggestedTruckSoc,
    suggestedTruckEngineGearBox,
    suggestedTruckChassisAdaptation,
    suggestedTruckWheelConfiguration,
    suggestedTruckAxleDistance,
    suggestedTruckChassisHeight,
    suggestedTruckCabType,
    suggestedBusPortfolio,
    suggestedBusBattery,
    suggestedBusSoc,
    suggestedBusChargingType,
    suggestedBusEngineGearBox,
    suggestedBusWheelConfiguration,
    suggestedBusBodyLength,
    suggestedBusChassisHeight,
    suggestedBusFloorHeight,
    setSelections,
  ]);
};

export default useLoadSelectionBySuggestion;
