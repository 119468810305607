import SetupEvaluation from './SetupEvaluation';
import { useParams } from 'react-router-dom';
import { VehicleEnhanced } from '@optimization/sa-common';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

type EvaluationStatus = {
  [key: string]: { isDone: boolean; enableInPresentation: boolean };
};

interface FinancialEvaluation {
  evaluations: EvaluationStatus;
  startSetupEvaluation: (vehicle: VehicleEnhanced) => void;
  setEnableEvaluationInPresentation: ({
    vehicleId,
    enableInPresentation,
  }: {
    vehicleId: number;
    enableInPresentation: boolean;
  }) => void;
}

const FinancialEvaluationContext = createContext<FinancialEvaluation | null>(null);

export const FinancialEvaluationProvider = ({ children }: { children: ReactNode }) => {
  const { solutionId } = useParams();

  const [vehicle, setVehicle] = useState<VehicleEnhanced | null>(null);

  const [evaluations, setEvaluations] = useState<EvaluationStatus>({});

  const cancelSetupEvaluation = useCallback(() => {
    setVehicle(null);
  }, []);

  const startSetupEvaluation = useCallback((vehicleParam: VehicleEnhanced) => {
    setVehicle(vehicleParam);
  }, []);

  const setEvaluationIsDone = useCallback(({ vehicleId, isDone }: { vehicleId: number; isDone: boolean }) => {
    setEvaluations((prev) => ({
      ...prev,
      [vehicleId.toString()]: {
        ...(prev[vehicleId.toString()] ? prev[vehicleId.toString()] : { enableInPresentation: false }),
        isDone,
      },
    }));
    setVehicle(null);
  }, []);

  const setEnableEvaluationInPresentation = useCallback(
    ({ vehicleId, enableInPresentation }: { vehicleId: number; enableInPresentation: boolean }) => {
      setEvaluations((prev) => ({
        ...prev,
        [vehicleId.toString()]: {
          ...(prev[vehicleId.toString()] ? prev[vehicleId.toString()] : { isDone: false }),
          enableInPresentation,
        },
      }));
    },
    [],
  );

  const value = useMemo(
    () => ({
      evaluations,
      startSetupEvaluation,
      setEnableEvaluationInPresentation,
    }),
    [evaluations, startSetupEvaluation, setEnableEvaluationInPresentation],
  );

  return (
    <FinancialEvaluationContext.Provider value={value}>
      {vehicle && solutionId ? (
        <SetupEvaluation
          solutionId={solutionId}
          vehicle={vehicle}
          cancelSetupEvaluation={cancelSetupEvaluation}
          setEvaluationIsDone={setEvaluationIsDone}
        />
      ) : (
        children
      )}
    </FinancialEvaluationContext.Provider>
  );
};

export const useFinancialEvaluation = () => useContext(FinancialEvaluationContext);
