import { Option } from 'common/components/Filters/utils/types';

const sortOptions: Option[] = [
  { type: 'created', name: 'Created' },
  { type: 'date', name: 'Last modified' },
  {
    type: 'launchPeriod',
    name: 'Launch period',
  },
  {
    type: 'chassisAssemblyDate',
    name: 'Chassis assembly date',
  },
  {
    type: 'dailyRange',
    name: 'Daily max. range',
  },
  {
    type: 'dailyRangePercent',
    name: 'Range feasibility',
  },
  {
    type: 'dailyDistance',
    name: 'Median daily distance',
  },
  {
    type: 'longestDailyDistance',
    name: 'Longest daily distance',
  },
  {
    type: 'performanceStep',
    name: 'Performance step',
  },
];

export default sortOptions;
