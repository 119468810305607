import useSelectedBasicBusEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicBusEarliest';
import useSelectedBasicTruckEarliest from 'features/candidate/utils/vehicleOptionsHandler/utils/useSelectedBasicTruckEarliest';
import { ExecutedVehicleProductV2VM, VehicleEnhanced } from '@optimization/sa-common';
import { getChargingTypeValue } from 'features/candidate/utils/vehicleOptionsHandler/defaultOptions/useBusChargingTypeOptions';
import { getEngineGearbox, getVehicleSoc } from 'common/utils/helper';
import { parseChargingTypeValue } from 'features/candidate/utils/vehicleOptionsHandler/defaultOptions/useBusChargingTypeOptions';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { useEffect, useMemo } from 'react';
import { useEnsureArray } from '@optimization/ssi-common';
import { useGetFactBasicDataQuery, useUpdateVehicleMutation } from 'app/services/solution';

interface Props {
  solutionId: string;
  vehicle: VehicleEnhanced;
  selections: SelectionExecutions;
  setSelections: React.Dispatch<React.SetStateAction<SelectionExecutions>>;
}

const useHandleVehicleConfiguration = ({ solutionId, vehicle, selections, setSelections }: Props) => {
  const [updateVehicle, updateVehicleState] = useUpdateVehicleMutation();

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const basicTrucks = useEnsureArray(factBasicDataQuery.data?.FactBasicTrucks);
  const basicBusses = useEnsureArray(factBasicDataQuery.data?.FactBasicBusses);

  const selectedBasicTruck = useSelectedBasicTruckEarliest({
    basicTrucks,
    selections,
  });

  const selectedBasicBus = useSelectedBasicBusEarliest({
    basicBusses,
    selections,
  });

  const selectedBasicVehicle = useMemo(
    () => (selections.chassisType === 'Truck' ? selectedBasicTruck : selectedBasicBus),
    [selectedBasicTruck, selectedBasicBus, selections.chassisType],
  );

  useEffect(() => {
    if (selections.isChangedByUser && selectedBasicVehicle) {
      let executedVehicleProduct: ExecutedVehicleProductV2VM = {
        ...vehicle.ExecutedVehicleProduct,
        CompositeId: selectedBasicVehicle.CompositeId,
        Status: selectedBasicVehicle.Status,
        LaunchPeriod: selectedBasicVehicle.Period,
        InstalledEnergyKWh: selectedBasicVehicle.InstalledEnergyKWh,
      };

      if (selections.chassisType === 'Truck') {
        if (selections.truckCabType) {
          if (!selectedBasicTruck) {
            return;
          }

          updateVehicle({
            vehicle: {
              ...vehicle,
              ExecutedVehicleProduct: {
                ...executedVehicleProduct,
                Soc: getVehicleSoc(selectedBasicTruck, selections.truckSocWindow),
                EngineGearbox: getEngineGearbox(selectedBasicTruck, selections.truckElectricMotor),
                ChassisAdaptation: selections.truckChassisAdaptation,
                WheelConfiguration: selections.truckWheelConfiguration,
                AxleDistanceMm: Number(selections.truckAxleDistance),
                ChassisHeight: selections.truckChassisHeight,
                Cab: selections.truckCabType,
              },
            },
            solutionId,
          });
        }
      } else if (selections.chassisType === 'Bus') {
        if (selections.busFloorHeight) {
          if (!selectedBasicBus) {
            return;
          }

          const chargingTypeParts = parseChargingTypeValue(selections.busChargingType);

          if (chargingTypeParts.length !== 2) {
            return;
          }

          const [chargingType, chargingPowerKW] = chargingTypeParts;

          updateVehicle({
            vehicle: {
              ...vehicle,
              ExecutedVehicleProduct: {
                ...executedVehicleProduct,
                Soc: getVehicleSoc(selectedBasicBus, selections.busSocWindow),
                ChargingSpecification: {
                  ChargingType: chargingType,
                  ChargingPowerKW: Number(chargingPowerKW),
                },
                EngineGearbox: getEngineGearbox(selectedBasicBus, selections.busElectricMotor),
                WheelConfiguration: selections.busWheelConfiguration,
                BodyLengthsM: selections.busBodyLength,
                ChassisHeight: selections.busChassisHeight,
                BusFloorHeight: selections.busFloorHeight,
              },
            },
            solutionId,
          });
        }
      }

      setSelections((prev) => ({ ...prev, isChangedByUser: false }));
    }
  }, [
    solutionId,
    vehicle,
    selections,
    selectedBasicVehicle,
    selectedBasicTruck,
    selectedBasicBus,
    setSelections,
    updateVehicle,
  ]);

  useEffect(() => {
    setSelections({
      chassisType: vehicle.ScaniaChassis?.ChassisType === 'Bus' ? 'Bus' : 'Truck',
      truckPortfolio: vehicle.ExecutedVehicleProduct?.LaunchPeriod || '',
      truckBattery: vehicle.ExecutedVehicleProduct?.InstalledEnergyKWh?.toString() || '',
      truckSocWindow: vehicle.ExecutedVehicleProduct.Soc?.SocWindow?.toString() || '',
      truckElectricMotor: vehicle.ExecutedVehicleProduct?.EngineGearbox?.Name || '',
      truckChassisAdaptation: vehicle.ExecutedVehicleProduct?.ChassisAdaptation || '',
      truckWheelConfiguration: vehicle.ExecutedVehicleProduct?.WheelConfiguration || '',
      truckAxleDistance: vehicle.ExecutedVehicleProduct?.AxleDistanceMm?.toString() || '',
      truckChassisHeight: vehicle.ExecutedVehicleProduct?.ChassisHeight || '',
      truckCabType: vehicle.ExecutedVehicleProduct?.Cab || '',
      busPortfolio: vehicle.ExecutedVehicleProduct?.LaunchPeriod || '',
      busBattery: vehicle.ExecutedVehicleProduct?.InstalledEnergyKWh?.toString() || '',
      busSocWindow: vehicle.ExecutedVehicleProduct.Soc?.SocWindow?.toString() || '',
      busChargingType: getChargingTypeValue({
        chargingType: vehicle.ExecutedVehicleProduct?.ChargingSpecification?.ChargingType,
        chargingPowerKW: vehicle.ExecutedVehicleProduct?.ChargingSpecification?.ChargingPowerKW,
      }),
      busElectricMotor: vehicle.ExecutedVehicleProduct?.EngineGearbox?.Name || '',
      busWheelConfiguration: vehicle.ExecutedVehicleProduct?.WheelConfiguration || '',
      busBodyLength: vehicle.ExecutedVehicleProduct?.BodyLengthsM || '',
      busChassisHeight: vehicle.ExecutedVehicleProduct?.ChassisHeight || '',
      busFloorHeight: vehicle.ExecutedVehicleProduct?.BusFloorHeight || '',
      isChangedByUser: false,
    });
  }, [vehicle, setSelections]);

  return useMemo(
    () => ({
      isLoading: updateVehicleState.isLoading,
      isError: updateVehicleState.isError,
    }),
    [updateVehicleState],
  );
};

export default useHandleVehicleConfiguration;
