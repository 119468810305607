import classNames from 'classnames';
import { SemanticColor } from '@optimization/sa-common';
import './style.scss';

export type InfoListItem = {
  label: string;
  value: string | number;
  extra?: string | number;
};

interface Props {
  items: InfoListItem[];
  color?: SemanticColor;
  className?: string;
  longValue?: boolean;
}

const InfoList = ({ items, color = 'positive', className, longValue }: Props) => (
  <ul className={classNames('info-list', className)}>
    {items.map((item, index) => (
      <li key={index}>
        <span className="label">{item.label}</span>
        <span
          className={classNames('value', {
            'value-large': longValue,
          })}
        >
          {item.value}
          {item.extra && <span className={`value-extra value-extra-${color}`}>{item.extra}</span>}
        </span>
      </li>
    ))}
  </ul>
);

export default InfoList;
