import classes from './style.module.scss';
import classNames from 'classnames';
import EnergyConsumption from './EnergyConsumption';
import { Modal } from '@optimization/ssi-common';
import { PtoDevices, VehicleEnhanced } from '@optimization/sa-common';
import { TdsIcon } from '@scania/tegel-react';
import { Tooltip } from '@optimization/ssi-common';
import { useCallback, useRef, useState } from 'react';

interface Props {
  className?: string;
  netEc: number;
  vehicle: VehicleEnhanced;
  ptoDevices?: PtoDevices;
  tooltipKey: number;
  isUpdateVehicleChargingEventsLoading: boolean;
  removeChargingEvents: () => Promise<void>;
}

const SimulatedEnergyConsumption = ({
  className,
  netEc,
  vehicle,
  ptoDevices,
  tooltipKey,
  isUpdateVehicleChargingEventsLoading,
  removeChargingEvents,
}: Props) => {
  const lockRef = useRef(null);

  const [deleteChargingEventsModalOpen, setDeleteChargingEventsModalOpen] = useState(false);

  const openChargingEventsModal = useCallback(() => {
    setDeleteChargingEventsModalOpen(true);
  }, []);

  const closeChargingEventsModal = useCallback(() => {
    setDeleteChargingEventsModalOpen(false);
  }, []);

  const confirmSubmitChargingEventsModal = useCallback(async () => {
    try {
      await removeChargingEvents();
      closeChargingEventsModal();
    } catch (e) {
      console.error(e);
    }
  }, [closeChargingEventsModal, removeChargingEvents]);

  return (
    <div className={classNames('flex', 'flex-col', className)}>
      {deleteChargingEventsModalOpen && (
        <Modal
          size="xs"
          header="Delete associated charging events"
          ctaConfirmText="Confirm"
          ctaConfirmSubmit={confirmSubmitChargingEventsModal}
          disabled={isUpdateVehicleChargingEventsLoading}
          onClose={closeChargingEventsModal}
        >
          Upon unlocking this vehicle candidate, all associated charging events will be deleted. Do you want to proceed?
        </Modal>
      )}
      <div className="flex">
        <h5 className={classNames('flex-grow-1', 'tds-detail-05', 'mb-spacing-16', 'mr-spacing-16')}>
          Simulated energy consumption
        </h5>
        {vehicle.enhanced.hasChargingEvents && (
          <div>
            <div ref={lockRef} className={classes.lock} onClick={openChargingEventsModal}>
              <span style={{ pointerEvents: 'none' }}>
                <TdsIcon size="16px" name="lock" />
              </span>
            </div>
            <Tooltip elRef={lockRef} key={tooltipKey}>
              <p>Unlock to adjust values that affect energy consumption</p>
            </Tooltip>
          </div>
        )}
      </div>
      {ptoDevices && (
        <EnergyConsumption
          netEc={netEc}
          ptoDevices={ptoDevices}
          executedVehicleProductPTOs={vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs}
        />
      )}
    </div>
  );
};

export default SimulatedEnergyConsumption;
