import classes from './style.module.scss';
import classNames from 'classnames';
import useOnChangeFieldValue from '../useOnChangeFieldValue';
import { Dropdown, TextField } from '@optimization/ssi-common';
import { FormValues, SetFormValue } from '../..';
import { useMemo } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';

const currencyOptions = [{ value: 'EUR', name: 'EUR' }];
const toCompareOptions = [{ value: 'BEV', name: 'BEV' }];

export const fromCompareOptions = [
  { value: 'diesel', name: 'Diesel' },
  { value: 'gas', name: 'Gas' },
];

interface Props {
  disabled?: boolean;
  className?: string;
  vehicle: VehicleEnhanced;
  formValues: FormValues;
  setFormValue: SetFormValue;
}

const GeneralInfoFields = ({ className, vehicle, disabled, formValues, setFormValue }: Props) => {
  const onChangeBevCompare = useOnChangeFieldValue({
    attribute: 'toCompare',
    setFormValue,
  });

  const onChangeDieselCompare = useOnChangeFieldValue({
    attribute: 'fromCompare',
    setFormValue,
  });

  const onChangeCurrency = useOnChangeFieldValue({
    attribute: 'currency',
    setFormValue,
  });

  const yearsOfOwnership = useMemo(() => {
    const assemblyDateStr = vehicle.ScaniaChassis?.AssemblyDate;

    if (assemblyDateStr) {
      const date = new Date(assemblyDateStr);
      return new Date().getFullYear() - date.getFullYear();
    }

    return 0;
  }, [vehicle]);

  return (
    <div className={classNames(classes['general-info-fields'], className)}>
      <div className={classNames('tds-detail-04', classes['label-1'])}>Currency</div>
      <Dropdown
        className={classes['field-1-1']}
        placeholder="Select currency"
        size="lg"
        disabled={disabled}
        options={currencyOptions}
        value={formValues.currency}
        onChange={onChangeCurrency}
      />
      <div className={classNames('tds-detail-04', classes['label-2'])}>Daily distance</div>
      <TextField
        className={classes['field-2-1']}
        type="number"
        value={vehicle.ExecutedVehicleProduct.DailyOdometerMedianKm.toString()}
        size="lg"
        suffixText="KM"
        disabled
        noMinWidth
      />
      <div className={classNames('tds-detail-04', classes['label-3'])}>Years of ownership</div>
      <TextField
        className={classes['field-3-1']}
        type="number"
        value={yearsOfOwnership.toString()}
        size="lg"
        suffixText="Years"
        disabled
        noMinWidth
      />
      <div className={classNames('tds-detail-04', classes['label-4'])}>Compare vehicles</div>
      <Dropdown
        className={classes['field-4-1']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={toCompareOptions}
        value={formValues.toCompare}
        onChange={onChangeBevCompare}
      />
      <Dropdown
        className={classes['field-4-2']}
        placeholder="Select"
        size="lg"
        disabled={disabled}
        options={fromCompareOptions}
        label="Optional"
        value={formValues.fromCompare}
        onChange={onChangeDieselCompare}
      />
    </div>
  );
};

export default GeneralInfoFields;
