import HelmetTitle from 'common/components/HelmetTitle';
import PageHeader from 'common/components/PageHeader';
import { Button, KeyedNumber, TextField } from '@optimization/ssi-common';
import { Mode } from '../';
import { useEffect, useMemo } from 'react';
import './style.scss';
import { BackLink, DepotChart, GetDepotResponseV2VM } from '@optimization/sa-common';

const formatTime = (time: string) => {
  const parts = time.split('-');
  return `${parts[0]}.00 - ${parts[1]}.00`;
};

interface Props {
  mode: Mode;
  solutionId: string;
  depotId?: string;
  depot?: GetDepotResponseV2VM;
  solutionQueryIsSuccess: boolean;
  depotName: string;
  depotMaxPowerCapacity: string;
  depotFutureMaxPowerCapacity: string;
  depotBasePowerDemand: KeyedNumber;
  isLoading: boolean;
  setDepotName: React.Dispatch<React.SetStateAction<string>>;
  setDepotMaxPowerCapacity: React.Dispatch<React.SetStateAction<string>>;
  setDepotFutureMaxPowerCapacity: React.Dispatch<React.SetStateAction<string>>;
  setDepotBasePowerDemand: React.Dispatch<React.SetStateAction<KeyedNumber>>;
  onSetupDepot: () => void;
  onUpdateDepot: () => void;
  onCancelDepotForm: () => void;
  submitOnEnterKey: (event: React.KeyboardEvent<HTMLTdsTextFieldElement>) => void;
}

const SetupAndUpdateForm = ({
  mode,
  solutionId,
  depotId,
  depot,
  solutionQueryIsSuccess,
  depotName,
  depotMaxPowerCapacity,
  depotFutureMaxPowerCapacity,
  depotBasePowerDemand,
  isLoading,
  setDepotName,
  setDepotMaxPowerCapacity,
  setDepotFutureMaxPowerCapacity,
  setDepotBasePowerDemand,
  onSetupDepot,
  onUpdateDepot,
  onCancelDepotForm,
  submitOnEnterKey,
}: Props) => {
  const depotMaxPowerCapacityNumber = useMemo(() => parseInt(depotMaxPowerCapacity) || 0, [depotMaxPowerCapacity]);

  const depotFutureMaxPowerCapacityNumber = useMemo(
    () => parseInt(depotFutureMaxPowerCapacity) || 0,
    [depotFutureMaxPowerCapacity],
  );

  useEffect(() => {
    if (!depot || mode === 'setup-depot') {
      return;
    }

    setDepotName(depot.Name);
    setDepotMaxPowerCapacity(depot.MaxPowerCapacity.toString());
    setDepotFutureMaxPowerCapacity(depot.FutureMaxPowerCapacity.toString());

    const powerDemand = ['00-06', '06-12', '12-18', '18-24'].map((time) => {
      return {
        [time]:
          depot?.PowerDemands.find((powerDemand) => powerDemand.TimeOfDay === `${time.slice(0, 2)}:00`)?.Value || 0,
      };
    });

    setDepotBasePowerDemand(Object.assign({}, ...powerDemand));
  }, [depot, mode, setDepotName, setDepotMaxPowerCapacity, setDepotFutureMaxPowerCapacity, setDepotBasePowerDemand]);

  const dataPlot = useMemo(
    () => [
      {
        label: 'Max. capacity',
        secondaryAxisId: '2',
        data: [
          {
            primary: 0,
            secondary: depotMaxPowerCapacityNumber,
          },
          {
            primary: 24,
            secondary: depotMaxPowerCapacityNumber,
          },
        ],
      },
      {
        label: 'Future max. capacity',
        secondaryAxisId: '3',
        data: [
          {
            primary: 0,
            secondary: depotFutureMaxPowerCapacityNumber,
          },
          {
            primary: 24,
            secondary: depotFutureMaxPowerCapacityNumber,
          },
        ],
      },
      {
        label: 'Base demand',
        secondaryAxisId: '1',
        data: [
          {
            primary: 0,
            secondary: depotBasePowerDemand['00-06'],
          },
          {
            primary: 6,
            secondary: depotBasePowerDemand['00-06'],
          },
          {
            primary: 6,
            secondary: depotBasePowerDemand['06-12'],
          },
          {
            primary: 12,
            secondary: depotBasePowerDemand['06-12'],
          },
          {
            primary: 12,
            secondary: depotBasePowerDemand['12-18'],
          },
          {
            primary: 18,
            secondary: depotBasePowerDemand['12-18'],
          },
          {
            primary: 18,
            secondary: depotBasePowerDemand['18-24'],
          },
          {
            primary: 24,
            secondary: depotBasePowerDemand['18-24'],
          },
        ],
      },
    ],
    [depotBasePowerDemand, depotFutureMaxPowerCapacityNumber, depotMaxPowerCapacityNumber],
  );

  const maxSecondaryAxes = useMemo(() => {
    const values = Object.values(depotBasePowerDemand).map((value) => value);
    const maxPowerCapacity = Math.max(depotFutureMaxPowerCapacityNumber, depotMaxPowerCapacityNumber, ...values);
    return maxPowerCapacity > 150 ? maxPowerCapacity + 20 : 150;
  }, [depotBasePowerDemand, depotFutureMaxPowerCapacityNumber, depotMaxPowerCapacityNumber]);

  return (
    <>
      {mode === 'setup-depot' && <HelmetTitle solutionId={solutionId} items={['Create new depot']} />}
      {mode === 'update-depot' && <HelmetTitle solutionId={solutionId} depotId={depotId} items={['Edit depot']} />}
      {solutionQueryIsSuccess && (
        <>
          <BackLink url="/" className="mb-spacing-64">
            All solutions
          </BackLink>
          <PageHeader
            className="max-w-550"
            intro="DEPOT"
            description={[
              `These are the definitions of the solution’s future electrified depots. Map vehicle candidates to them, and define daily charging events, in yearly performance steps. You can also define and dimension the required depot charging infrastructure.`,
            ]}
            descriptionAligment="left"
          >
            {mode === 'setup-depot' ? 'Create' : 'Edit'} depot
          </PageHeader>
          <div className="depot-form-fields">
            <div className="body mt-spacing-64">
              <div className="body-col">
                <div>
                  <h3 className="tds-detail-04 mb-spacing-4">Depot setup</h3>
                  <div>
                    <TextField
                      onChange={(e) => setDepotName(e)}
                      onKeyUp={submitOnEnterKey}
                      className="w-full"
                      label="Name"
                      variant="primary"
                      labelPosition="inside"
                      dataTestid="textfield-depot-name"
                      size="lg"
                      value={depotName}
                      disabled={isLoading}
                      placeholder="ex. city, street or geo location"
                    />
                  </div>
                  <div className="capacity-fields mt-spacing-28">
                    <TextField
                      onChange={(e) => setDepotMaxPowerCapacity(e)}
                      onKeyUp={submitOnEnterKey}
                      label="Max. power capacity"
                      type="number"
                      variant="primary"
                      labelPosition="inside"
                      dataTestid="textfield-depot-max-power-capacity"
                      size="lg"
                      disabled={isLoading}
                      value={depotMaxPowerCapacity.toString()}
                      suffixText="kW"
                      min={0}
                    />
                    <TextField
                      onChange={(e) => setDepotFutureMaxPowerCapacity(e)}
                      onKeyUp={submitOnEnterKey}
                      label="Future power capacity"
                      type="number"
                      variant="primary"
                      labelPosition="inside"
                      dataTestid="textfield-depot-future-max-power-capacity"
                      size="lg"
                      disabled={isLoading}
                      value={depotFutureMaxPowerCapacity.toString()}
                      suffixText="kW"
                      min={0}
                    />
                  </div>
                </div>
                <div className="mt-spacing-28">
                  <h3 className="tds-detail-04 mb-spacing-4">Base power demand</h3>
                  <div className="base-power-demand-fields">
                    {Object.entries(depotBasePowerDemand).map(([time]) => (
                      <div key={time} className="">
                        <TextField
                          onChange={(e) =>
                            setDepotBasePowerDemand((prev) => ({
                              ...prev,
                              [time]: Number(e),
                            }))
                          }
                          onKeyUp={submitOnEnterKey}
                          disabled={isLoading}
                          helper={formatTime(time)}
                          type="number"
                          variant="primary"
                          labelPosition="inside"
                          dataTestid={`textfield-depot-base-power-demand-${time}`}
                          suffixText="kW"
                          min={0}
                          value={depotBasePowerDemand[time as keyof typeof depotBasePowerDemand].toString()}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <DepotChart
                className="body-col"
                depotMaxPowerCapacity={depotMaxPowerCapacityNumber}
                depotFutureMaxPowerCapacity={depotFutureMaxPowerCapacityNumber}
                dataPlot={dataPlot}
                noToolTip
                maxSecondaryAxes={maxSecondaryAxes}
              />
            </div>
          </div>
          <div className="flex gap-16 justify-end mt-spacing-38">
            <Button
              disabled={!depotName || isLoading}
              onClick={mode === 'setup-depot' ? onSetupDepot : onUpdateDepot}
              dataTestid={mode === 'update-depot' ? 'button-update-depot' : 'button-setup-depot'}
              text={mode === 'update-depot' ? 'Apply changes' : `${mode === 'setup-depot' ? 'Setup' : 'Edit'} depot`}
            />
            <Button variant="secondary" text="Cancel" disabled={isLoading} onClick={onCancelDepotForm} />
          </div>
        </>
      )}
    </>
  );
};

export default SetupAndUpdateForm;
