import classes from './style.module.scss';
import classNames from 'classnames';
import { BUS_ENABLED } from 'app/config';
import { Dropdown } from '@optimization/ssi-common';
import { RadioButtons } from '@optimization/ssi-common';
import { useCallback } from 'react';
import { VehicleOptionsHandler } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';

interface Props {
  disabled: boolean;
  className?: string;
  vehicleOptionsHandler: VehicleOptionsHandler;
  dataTestidBase?: string;
}

const VehicleConfiguration = ({
  disabled,
  className,
  vehicleOptionsHandler,
  dataTestidBase = 'vehicle-configuration',
}: Props) => {
  const {
    chassisTypeOptions,
    truckPortfolioOptions,
    truckBatteryOptions,
    truckBatteryDisabled,
    truckSocOptions,
    truckSocDisabled,
    truckEngineGearBoxOptions,
    truckEngineGearBoxDisabled,
    truckChassisAdaptationOptions,
    truckChassisAdaptationDisabled,
    truckWheelConfigurationOptions,
    truckWheelConfigurationDisabled,
    truckAxleDistanceOptions,
    truckAxleDistanceDisabled,
    truckChassisHeightOptions,
    truckChassisHeightDisabled,
    truckCabTypeOptions,
    truckCabTypeDisabled,
    busPortfolioOptions,
    busBatteryOptions,
    busBatteryDisabled,
    busSocOptions,
    busSocDisabled,
    busEngineGearBoxOptions,
    busEngineGearBoxDisabled,
    busWheelConfigurationOptions,
    busWheelConfigurationDisabled,
    busBodyLengthOptions,
    busBodyLengthDisabled,
    busChassisHeightOptions,
    busChassisHeightDisabled,
    busFloorHeightOptions,
    busFloorHeightDisabled,
    busChargingTypeOptions,
    busChargingTypeDisabled,
    selections,
    handleSelection,
  } = vehicleOptionsHandler;

  const handleChassisTypeSelection = useCallback(
    (value: string) => handleSelection('chassisType', value),
    [handleSelection],
  );

  const handleTruckPortfolioSelection = useCallback(
    (value: string) => handleSelection('truckPortfolio', value),
    [handleSelection],
  );

  const handleTruckBatterySelection = useCallback(
    (value: string) => handleSelection('truckBattery', value),
    [handleSelection],
  );

  const handleTruckSocWindowSelection = useCallback(
    (value: string) => handleSelection('truckSocWindow', value),
    [handleSelection],
  );

  const handleTruckElectricMotorSelection = useCallback(
    (value: string) => handleSelection('truckElectricMotor', value),
    [handleSelection],
  );

  const handleTruckChassisAdaptationSelection = useCallback(
    (value: string) => handleSelection('truckChassisAdaptation', value),
    [handleSelection],
  );

  const handleTruckWheelConfigurationSelection = useCallback(
    (value: string) => handleSelection('truckWheelConfiguration', value),
    [handleSelection],
  );

  const handleTruckAxleDistanceSelection = useCallback(
    (value: string) => handleSelection('truckAxleDistance', value),
    [handleSelection],
  );

  const handleTruckChassisHeightSelection = useCallback(
    (value: string) => handleSelection('truckChassisHeight', value),
    [handleSelection],
  );

  const handleTruckCabTypeSelection = useCallback(
    (value: string) => handleSelection('truckCabType', value),
    [handleSelection],
  );

  const handleBusPortfolioSelection = useCallback(
    (value: string) => handleSelection('busPortfolio', value),
    [handleSelection],
  );

  const handleBusBatterySelection = useCallback(
    (value: string) => handleSelection('busBattery', value),
    [handleSelection],
  );

  const handleBusSocWindowSelection = useCallback(
    (value: string) => handleSelection('busSocWindow', value),
    [handleSelection],
  );

  const handleBusElectricMotorSelection = useCallback(
    (value: string) => handleSelection('busElectricMotor', value),
    [handleSelection],
  );

  const handleBusWheelConfigurationSelection = useCallback(
    (value: string) => handleSelection('busWheelConfiguration', value),
    [handleSelection],
  );

  const handleBusBodyLengthSelection = useCallback(
    (value: string) => handleSelection('busBodyLength', value),
    [handleSelection],
  );

  const handleBusChassisHeightSelection = useCallback(
    (value: string) => handleSelection('busChassisHeight', value),
    [handleSelection],
  );

  const handleBusFloorHeightSelection = useCallback(
    (value: string) => handleSelection('busFloorHeight', value),
    [handleSelection],
  );

  const handleBusChargingTypeSelection = useCallback(
    (value: string) => handleSelection('busChargingType', value),
    [handleSelection],
  );

  return (
    <div className={classNames(className, classes.fields)}>
      {BUS_ENABLED && (
        <>
          <div>Type</div>
          <RadioButtons onChange={handleChassisTypeSelection} options={chassisTypeOptions} />
        </>
      )}
      {selections.chassisType === 'Truck' && (
        <>
          <div>Product portfolio</div>
          <Dropdown
            value={selections.truckPortfolio}
            placeholder="Select an option..."
            disabled={disabled}
            options={truckPortfolioOptions}
            dataTestid={`${dataTestidBase}-portfolio`}
            onChange={handleTruckPortfolioSelection}
          />
          <div>Battery</div>
          <Dropdown
            value={selections.truckBattery}
            placeholder="Select an option..."
            dataTestid={`${dataTestidBase}-battery`}
            options={truckBatteryOptions}
            disabled={truckBatteryDisabled || disabled}
            onChange={handleTruckBatterySelection}
          />
          <div>SoC window</div>
          <Dropdown
            value={selections.truckSocWindow}
            placeholder="Select an option..."
            options={truckSocOptions}
            dataTestid={`${dataTestidBase}-soc-window`}
            disabled={truckSocDisabled || disabled}
            onChange={handleTruckSocWindowSelection}
          />
          <div>Electric motor</div>
          <Dropdown
            value={selections.truckElectricMotor}
            placeholder="Select an option..."
            options={truckEngineGearBoxOptions}
            dataTestid={`${dataTestidBase}-electric-motor`}
            disabled={truckEngineGearBoxDisabled || disabled}
            onChange={handleTruckElectricMotorSelection}
          />
          <div>Chassis adaptation</div>
          <Dropdown
            value={selections.truckChassisAdaptation}
            placeholder="Select an option..."
            options={truckChassisAdaptationOptions}
            dataTestid={`${dataTestidBase}-chassis-adaptation`}
            disabled={truckChassisAdaptationDisabled || disabled}
            onChange={handleTruckChassisAdaptationSelection}
          />
          <div>Wheel configuration</div>
          <Dropdown
            value={selections.truckWheelConfiguration}
            placeholder="Select an option..."
            options={truckWheelConfigurationOptions}
            dataTestid={`${dataTestidBase}-wheel-configuration`}
            disabled={truckWheelConfigurationDisabled || disabled}
            onChange={handleTruckWheelConfigurationSelection}
          />
          <div>Axle distance</div>
          <Dropdown
            value={selections.truckAxleDistance}
            placeholder="Select an option..."
            options={truckAxleDistanceOptions}
            dataTestid={`${dataTestidBase}-axle-distance`}
            disabled={truckAxleDistanceDisabled || disabled}
            onChange={handleTruckAxleDistanceSelection}
          />
          <div>Chassis height</div>
          <Dropdown
            value={selections.truckChassisHeight}
            placeholder="Select an option..."
            options={truckChassisHeightOptions}
            dataTestid={`${dataTestidBase}-chassis-height`}
            disabled={truckChassisHeightDisabled || disabled}
            onChange={handleTruckChassisHeightSelection}
          />
          <div>Cab type</div>
          <Dropdown
            value={selections.truckCabType}
            placeholder="Select an option..."
            options={truckCabTypeOptions}
            dataTestid={`${dataTestidBase}-cab-type`}
            disabled={truckCabTypeDisabled || disabled}
            openDirection="up"
            onChange={handleTruckCabTypeSelection}
          />
        </>
      )}
      {selections.chassisType === 'Bus' && (
        <>
          <div>Product portfolio</div>
          <Dropdown
            value={selections.busPortfolio}
            placeholder="Select an option..."
            disabled={disabled}
            options={busPortfolioOptions}
            dataTestid={`${dataTestidBase}-portfolio`}
            onChange={handleBusPortfolioSelection}
          />
          <div>Battery</div>
          <Dropdown
            value={selections.busBattery}
            placeholder="Select an option..."
            dataTestid={`${dataTestidBase}-battery`}
            options={busBatteryOptions}
            disabled={busBatteryDisabled || disabled}
            onChange={handleBusBatterySelection}
          />
          <div>SoC window</div>
          <Dropdown
            value={selections.busSocWindow}
            placeholder="Select an option..."
            options={busSocOptions}
            dataTestid={`${dataTestidBase}-soc-window`}
            disabled={busSocDisabled || disabled}
            onChange={handleBusSocWindowSelection}
          />
          <div>Charging type</div>
          <Dropdown
            value={selections.busChargingType}
            placeholder="Select an option..."
            options={busChargingTypeOptions}
            dataTestid={`${dataTestidBase}-charging-type`}
            disabled={busChargingTypeDisabled || disabled}
            onChange={handleBusChargingTypeSelection}
          />
          <div>Electric motor</div>
          <Dropdown
            value={selections.busElectricMotor}
            placeholder="Select an option..."
            options={busEngineGearBoxOptions}
            dataTestid={`${dataTestidBase}-electric-motor`}
            disabled={busEngineGearBoxDisabled || disabled}
            onChange={handleBusElectricMotorSelection}
          />
          <div>Wheel configuration</div>
          <Dropdown
            value={selections.busWheelConfiguration}
            placeholder="Select an option..."
            options={busWheelConfigurationOptions}
            dataTestid={`${dataTestidBase}-wheel-configuration`}
            disabled={busWheelConfigurationDisabled || disabled}
            onChange={handleBusWheelConfigurationSelection}
          />
          <div>Body length</div>
          <Dropdown
            value={selections.busBodyLength}
            placeholder="Select an option..."
            options={busBodyLengthOptions}
            dataTestid={`${dataTestidBase}-body-length`}
            disabled={busBodyLengthDisabled || disabled}
            onChange={handleBusBodyLengthSelection}
          />
          <div>Chassis height</div>
          <Dropdown
            value={selections.busChassisHeight}
            placeholder="Select an option..."
            options={busChassisHeightOptions}
            dataTestid={`${dataTestidBase}-chassis-height`}
            disabled={busChassisHeightDisabled || disabled}
            onChange={handleBusChassisHeightSelection}
          />
          <div>Floor height</div>
          <Dropdown
            value={selections.busFloorHeight}
            placeholder="Select an option..."
            options={busFloorHeightOptions}
            dataTestid={`${dataTestidBase}-floor-height`}
            disabled={busFloorHeightDisabled || disabled}
            openDirection="up"
            onChange={handleBusFloorHeightSelection}
          />
        </>
      )}
    </div>
  );
};

export default VehicleConfiguration;
