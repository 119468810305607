import { Charger } from 'features/infrastructure/utils/InfraPerformanceStep/utils/types';
import { DepotBasePowerDemand } from './useDepotBasePowerDemands';
import { Mode } from '../';
import { useCallback, useEffect, useMemo } from 'react';
import { useEnterKeyInTextField, useToast } from '@optimization/ssi-common';
import { useNavigate } from 'react-router-dom';
import { GetDepotResponseV2VM, PowerDeliveryMode } from '@optimization/sa-common';
import { useAddDepotMutation, useUpdateDepotMutation } from 'app/services/solution';

interface Props {
  depotBasePowerDemands: DepotBasePowerDemand[];
  chargers: Charger[];
  depot?: GetDepotResponseV2VM;
  depotName: string;
  depotMaxPowerCapacity: string;
  depotFutureMaxPowerCapacity: string;
  mode: Mode;
  selectedCandidates: string[];
  solutionId: string;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
}

const useCreateAndUpdateDepot = ({
  depotBasePowerDemands,
  chargers,
  depot,
  depotName,
  depotMaxPowerCapacity,
  depotFutureMaxPowerCapacity,
  mode,
  selectedCandidates,
  solutionId,
  setMode,
}: Props) => {
  const navigate = useNavigate();
  const showToast = useToast();

  const [updateDepot, updateDepotState] = useUpdateDepotMutation();
  const [addDepotMutation, addDepotMutationState] = useAddDepotMutation();

  const depotMaxPowerCapacityNumber = useMemo(() => parseInt(depotMaxPowerCapacity) || 0, [depotMaxPowerCapacity]);

  const depotFutureMaxPowerCapacityNumber = useMemo(
    () => parseInt(depotFutureMaxPowerCapacity) || 0,
    [depotFutureMaxPowerCapacity],
  );

  const onSetupDepot = useCallback(() => {
    setMode('map-vehicles');
  }, [setMode]);

  const onCreateDepot = useCallback(() => {
    addDepotMutation({
      solutionId,
      Name: depotName,
      MaxPowerCapacity: depotMaxPowerCapacityNumber,
      FutureMaxPowerCapacity: depotFutureMaxPowerCapacityNumber,
      PowerDemands: depotBasePowerDemands,
      MappedVehicles: selectedCandidates.map(Number),
      InfrastructureProducts: chargers.map((charger) => ({
        Brand: charger.brand.code,
        Name: '',
        Outlets: charger.satellites.code,
        Power: charger.power.code,
        PowerDeliveryMode: PowerDeliveryMode.Democratic,
        Quantity: charger.quantity,
        RequiredAt: charger.year.toString(),
        Solution: charger.solution.code,
      })),
    });
  }, [
    chargers,
    depotName,
    solutionId,
    selectedCandidates,
    depotFutureMaxPowerCapacityNumber,
    depotMaxPowerCapacityNumber,
    depotBasePowerDemands,
    addDepotMutation,
  ]);

  const onUpdateDepot = useCallback(() => {
    if (!depot || !depotName) {
      return;
    }

    updateDepot({
      ...depot,
      solutionId,
      depotId: depot.Id,
      Name: depotName,
      MaxPowerCapacity: depotMaxPowerCapacityNumber,
      FutureMaxPowerCapacity: depotFutureMaxPowerCapacityNumber,
      PowerDemands: depotBasePowerDemands,
      InfrastructureProducts: depot.InfrastructureProducts.map((product) => ({
        ...product,
        Name: '',
      })),
    });
  }, [
    depot,
    depotName,
    solutionId,
    depotFutureMaxPowerCapacityNumber,
    depotMaxPowerCapacityNumber,
    depotBasePowerDemands,
    updateDepot,
  ]);

  const submitOnEnterKey = useEnterKeyInTextField(mode === 'setup-depot' ? onSetupDepot : onUpdateDepot);

  useEffect(() => {
    if (mode === 'update-depot' && updateDepotState.isSuccess) {
      showToast({ header: 'Depot has been updated' });
      return navigate(`/solution/${solutionId}/infrastructure/${depot?.Id}`);
    }
  }, [depot, updateDepotState, solutionId, mode, navigate, showToast]);

  useEffect(() => {
    if (mode === 'depot-infrastructure') {
      const id = addDepotMutationState.data?.Id;

      if (id && addDepotMutationState.isSuccess) {
        showToast({
          header: 'Depot has been created',
          dataTestid: 'toast-depot-created',
        });

        return navigate(`/solution/${solutionId}/infrastructure/${id}`);
      } else if (addDepotMutationState.isError) {
        showToast({
          header: 'Something went wrong',
          subheader: `Couldn't save depot correctly`,
          variant: 'error',
        });
      }
    }
  }, [mode, selectedCandidates, solutionId, addDepotMutationState, updateDepotState, navigate, showToast]);

  const isLoading = addDepotMutationState.isLoading || updateDepotState.isLoading;

  const isError = addDepotMutationState.isError || updateDepotState.isError;

  return useMemo(
    () => ({
      isLoading,
      isError,
      onSetupDepot,
      onCreateDepot,
      onUpdateDepot,
      submitOnEnterKey,
    }),
    [isLoading, isError, onSetupDepot, onCreateDepot, onUpdateDepot, submitOnEnterKey],
  );
};

export default useCreateAndUpdateDepot;
