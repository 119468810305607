import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { OnConfigureVehicleProduct } from 'features/solution/SolutionDetail/utils/useConfigureVehicleProductHandler';
import { SelectionExecutions } from 'features/candidate/utils/vehicleOptionsHandler/useVehicleOptionsHandler';
import { useCallback } from 'react';

interface UseSubmitChangedCandidatesProps {
  selectedBasicTruck: FactBasicTruck | null;
  selectedBasicBus: FactBasicBus | null;
  selections: SelectionExecutions;
  onConfigureVehicleProduct: OnConfigureVehicleProduct;
}

const useSubmitChangedCandidates = ({
  selectedBasicTruck,
  selectedBasicBus,
  selections,
  onConfigureVehicleProduct,
}: UseSubmitChangedCandidatesProps) =>
  useCallback(() => {
    onConfigureVehicleProduct({ selections, selectedBasicTruck, selectedBasicBus });
  }, [selectedBasicTruck, selectedBasicBus, selections, onConfigureVehicleProduct]);

export default useSubmitChangedCandidates;
