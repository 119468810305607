import { useMemo } from 'react';
import { cellToLatLng } from 'h3-js';

interface Props {
  stopAggregates?: {
    ChassisList: string[];
    H3CellIndex: string;
    MaxStopStartTime: string;
    MedianStopDuration: number;
    MinStopStartTime: string;
    StopCount: number;
    SumFuelToNext: number;
    SumOdometerToNext: number;
    SumStopDuration: number;
  }[];
  run: boolean;
}

const useStops = ({ run, stopAggregates }: Props) =>
  useMemo(() => {
    if (run && stopAggregates) {
      return stopAggregates.map((stop) => {
        const coord = cellToLatLng(stop.H3CellIndex);

        return {
          h3Cell: stop.H3CellIndex,
          lat: coord[0],
          lng: coord[1],
          stopCount: stop.StopCount,
          medianStopDuration: stop.MedianStopDuration,
          chassisList: stop.ChassisList,
        };
      });
    }

    return [];
  }, [stopAggregates, run]);

export default useStops;
