import useChargerVariantValues from 'features/infrastructure/utils/useChargerVariantValues';
import { FactBasicChargingSolution, GetDepotResponseV2VM, InfrastructureProductV2VM } from '@optimization/sa-common';
import { getChargersFromInstructureProducts, getStepIsComplete } from './helper';
import { InfraPerformanceStep } from './types';
import { useMemo } from 'react';

interface Props {
  depot?: GetDepotResponseV2VM;
  depotYear: string;
  chargingSolutions?: FactBasicChargingSolution[];
  infrastructureProducts?: InfrastructureProductV2VM[];
}

const useInfraPerformanceStep = ({
  depot,
  depotYear,
  chargingSolutions,
  infrastructureProducts,
}: Props): InfraPerformanceStep => {
  const variantValues = useChargerVariantValues(chargingSolutions);
  const year = useMemo(() => parseInt(depotYear), [depotYear]);

  return useMemo(() => {
    const chargers = getChargersFromInstructureProducts({
      infrastructureProducts,
      variantValues,
      chargingSolutions,
      year,
    });

    chargers.sort(function (a, b) {
      if (a.year > b.year) return 1;
      if (a.year < b.year) return -1;
      return 0;
    });

    const peakChargingPowerDemand = depot?.DepotDataPerPerformanceStep?.[depotYear]?.PowerDemand || 0;

    const totalMaxChargingPower = depot?.DepotDataPerPerformanceStep?.[depotYear]?.ChargingPower || 0;

    const peakConnectedVehicles = depot?.DepotDataPerPerformanceStep?.[depotYear]?.PeakConnectedVehicles || 0;

    const satellitesCount = depot?.DepotDataPerPerformanceStep?.[depotYear]?.NumberOfSatellites || 0;

    const notEnoughPower = depot?.DepotDataPerPerformanceStep?.[depotYear]?.ErrorStates?.NotEnoughPower || false;

    const notEnoughPowerBlocks =
      depot?.DepotDataPerPerformanceStep?.[depotYear]?.ErrorStates?.NotEnoughPowerBlocks || false;

    const notEnoughSatellites =
      depot?.DepotDataPerPerformanceStep?.[depotYear]?.ErrorStates?.NotEnoughSatellites || false;

    const energyConsumptionPerDayKwh = depot?.DepotDataPerPerformanceStep?.[depotYear]?.EnergyConsumptionPerDayKwh || 0;

    const maxUsedPowerBlocks = depot?.DepotDataPerPerformanceStep?.[depotYear]?.MaxUsedPowerBlocks || 0;

    const totalNumberOfPowerBlocks = depot?.DepotDataPerPerformanceStep?.[depotYear]?.TotalNumberOfPowerBlocks || 0;

    return {
      year,
      totalMaxChargingPower,
      peakConnectedVehicles,
      satellitesCount,
      peakChargingPowerDemand,
      chargers,
      energyConsumptionPerDayKwh,
      maxUsedPowerBlocks,
      totalNumberOfPowerBlocks,
      notEnoughPower,
      notEnoughPowerBlocks,
      notEnoughSatellites,
      stepIsComplete: getStepIsComplete({
        notEnoughPower,
        notEnoughPowerBlocks,
        notEnoughSatellites,
      }),
    };
  }, [depot, depotYear, infrastructureProducts, chargingSolutions, variantValues, year]);
};

export default useInfraPerformanceStep;
