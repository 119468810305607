import chargingSpecificationCheck from './chargingSpecificationCheck';
import chassisAdaptationCheck from './chassisAdaptationCheck';
import { FactBasicBus } from '@optimization/sa-common';
import { SelectionExecutions } from '../useVehicleOptionsHandler';
import { useMemo } from 'react';

interface Props {
  basicBusses: FactBasicBus[];
  selections: SelectionExecutions;
}

const useBusChassisHeights = ({ basicBusses, selections }: Props) =>
  useMemo(
    () => [
      ...new Set(
        basicBusses
          .filter(
            (basicBus) =>
              chassisAdaptationCheck({
                basicVehicle: basicBus,
                selectedBattery: selections.busBattery,
                selectedSocWindow: selections.busSocWindow,
                selectedElectricMotor: selections.busElectricMotor,
                selectedPortfolio: selections.busPortfolio,
              }) &&
              chargingSpecificationCheck({
                chargingSpecifications: basicBus.ChargingSpecifications,
                selectedChargingType: selections.busChargingType,
              }) &&
              basicBus.WheelConfiguration === selections.busWheelConfiguration.toLocaleLowerCase() &&
              basicBus.BodyLengthsM.includes(selections.busBodyLength),
          )
          .map((basicBus) => basicBus.ChassisHeights)
          .flat(),
      ),
    ],
    [basicBusses, selections],
  );

export default useBusChassisHeights;
