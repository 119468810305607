import classNames from 'classnames';
import { TdsIcon } from '@scania/tegel-react';
import { useMemo } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';
import './style.scss';

export type MenuItemName = 'Vehicle configuration' | 'Operational factors' | 'PTO device';

export interface MenuItem {
  name: MenuItemName;
  dataTestid?: string;
  hasDot?: boolean;
}

const menuItems: MenuItem[] = [
  { name: 'Vehicle configuration' },
  { name: 'Operational factors', dataTestid: 'button-operational-factors' },
  { name: 'PTO device', dataTestid: 'button-pto-device' },
];

interface Props {
  vehicle: VehicleEnhanced;
  activeMenuItemTitleName: string;
  setActiveMenuItemTitleName: React.Dispatch<React.SetStateAction<MenuItemName>>;
}

const Menu = ({ vehicle, activeMenuItemTitleName, setActiveMenuItemTitleName }: Props) => {
  const filteredMenuItems = useMemo(
    () =>
      menuItems
        .filter((menuItem) => menuItem.name === 'Vehicle configuration' || vehicle.ExecutedVehicleProduct.CompositeId)
        .map((menuItem) => ({
          ...menuItem,
          hasDot:
            menuItem.name === 'PTO device' && vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs.length === 0,
        })),
    [vehicle],
  );

  return (
    <ul className="candidate-detail-menu">
      {filteredMenuItems.map((menuItem) => (
        <li key={menuItem.name}>
          <button
            type="button"
            data-testid={menuItem.dataTestid}
            onClick={() => setActiveMenuItemTitleName(menuItem.name)}
            className={classNames('reset-button-styles', 'candidate-detail-menu-item', 'tds-detail-04', {
              'candidate-detail-menu-item-active': menuItem.name === activeMenuItemTitleName,
            })}
          >
            {menuItem.hasDot && <div className="candidate-detail-menu-item-dot" />}
            <div>{menuItem.name}</div>
            <TdsIcon name="arrow_right" />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Menu;
